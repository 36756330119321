import { ResourceStoreContext, RouterContext, RouterDataContext } from 'react-resource-router';

import { getCustomColumns, getStandardColumns } from '@townsquare/columns/hooks/use-shown-columns';
import { resolveRawSort } from '@townsquare/directory-header/sort';
import { RelayResourceConfig, createRelayResource } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';

import ProjectDirectoryQuery, {
  type DirectoryViewProjectQuery as ProjectDirectoryViewQueryType,
  type ProjectSortEnum,
} from '../components/__generated__/DirectoryViewProjectQuery.graphql';
import { DEFAULT_TABLE_PROJECT_SORTS, DEFAULT_TIMELINE_PROJECT_SORTS } from '../default-constants';
import {
  getDefaultProjectTableTqlQuery,
  getDefaultProjectTimelineTqlQuery,
  getStandardColumnRelayVariables,
} from '../utils';

export const DEFAULT_FIRST = 50;

export const getProjectDirectoryViewResourceQuery = (
  routerContext: RouterContext | RouterDataContext,
  resourceContext: ResourceStoreContext,
): RelayResourceConfig<ProjectDirectoryViewQueryType> => {
  const context = resourceContext as ResourceContext;

  const isSavedView = !!routerContext.query.viewUuid;
  const isTable = Boolean(routerContext.query.view !== 'timeline');
  const isTableOrSavedView = isTable || isSavedView;
  const isTimelineOrSavedView = !isTable || isSavedView;
  const defaultTql = isTable ? getDefaultProjectTableTqlQuery() : getDefaultProjectTimelineTqlQuery();
  const defaultSort = isTable ? DEFAULT_TABLE_PROJECT_SORTS : DEFAULT_TIMELINE_PROJECT_SORTS;
  const shouldUseDefaults = !routerContext.query.viewUuid;

  const selectedStandardColumns = getStandardColumns('projects', routerContext.query.columns);
  const selectedCustomColumns = getCustomColumns(routerContext.query.columns);

  return {
    query: ProjectDirectoryQuery,
    variables: {
      tql: routerContext.query.tql || (shouldUseDefaults ? defaultTql : null),
      first: DEFAULT_FIRST,
      sort: resolveRawSort<ProjectSortEnum>(routerContext.query.sort) ?? (shouldUseDefaults ? defaultSort : null),
      workspaceUuid: context.workspace?.UUID || '',
      directoryViewUuid: routerContext.query.viewUuid,
      isTableOrSavedView,
      isTimelineOrSavedView,
      ...getStandardColumnRelayVariables(selectedStandardColumns),
      includedCustomFieldUuids: selectedCustomColumns,
      // We have to manually refetch once the columns have loaded in from the view.
      // See DirectoryTable.tsx
      skipTableTql: isSavedView && routerContext.query.columns === undefined,
    },
    options: {
      fetchPolicy: 'store-and-network',
    },
  };
};

export const projectDirectoryViewResource = createRelayResource<ProjectDirectoryViewQueryType>({
  getQuery: getProjectDirectoryViewResourceQuery,
});
