import { searchPageLoadUFOExperience } from '@atlassian/search-page/experiences';

import Loadable from '@townsquare/loadable';

export const SearchView = Loadable<any, any>({
  loader: () => {
    searchPageLoadUFOExperience.start();
    return import(/* webpackChunkName: "SearchView" */ './SearchView');
  },
  loading: () => null,
});

export { searchConfigurationResource } from './searchConfigurationResource';
