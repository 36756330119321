import ButtonLegacy from '@atlaskit/button';
import Button from '@atlaskit/button/new';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { EmojiPreviewer } from '@townsquare/project-icon';
import { useIsNavRefreshEnabled } from '@townsquare/stat-sig/nav4';

interface Props {
  onClick: () => void;
}

export const ReadingModeLinkButton = (props: Props) => {
  const homeVisualRefreshEnabled = useIsNavRefreshEnabled();

  if (homeVisualRefreshEnabled) {
    return (
      <Button onClick={props.onClick} spacing="default" testId="reading-mode-button">
        <FormattedMessage
          description="Button text to trigger reading mode"
          defaultMessage="Catch up"
          id="townsquare.home.reading-mode.button-catch-up"
        />
      </Button>
    );
  }

  return (
    <ButtonLegacy
      iconBefore={<EmojiPreviewer shortName=":book:" size="medium" />}
      label=""
      onClick={props.onClick}
      spacing="compact"
      testId="reading-mode-button"
    >
      <FormattedMessage
        description="Button text to trigger reading mode"
        defaultMessage="Reading mode"
        id="townsquare.home.reading-mode.button-text"
      />
    </ButtonLegacy>
  );
};
