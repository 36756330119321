import { userIdToAri } from '@townsquare/ari-utils';
import { AggRelayEnvironment, createRelayResource } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';
import { isGateEnabled } from '@townsquare/stat-sig/gate';

import navigationCustomisationQuery, {
  type navigationCustomisationQuery as navigationCustomisationQueryType,
} from '../components/sidebar/CustomisableSidebar/__generated__/navigationCustomisationQuery.graphql';
import { getCustomisationEntityAri } from '../util';

export const sidebarContentResource = createRelayResource<navigationCustomisationQueryType>({
  /**
   * Allow cache to live for 30 minutes during a single SPA session
   * This is to avoid the sidebar flickering because queryRef is null in-between re-renders / SPA navigations
   *
   * Once 30 mins is up, the sidebar will flicker once and be back to normal again.
   * This is a compromise.
   */
  maxAge: 60_000 * 30,
  getQuery: (routerContext, resourceContext) => {
    const context = resourceContext as ResourceContext;
    const workspace = context.workspace;

    const entityAri = getCustomisationEntityAri(workspace?.cloudId, workspace?.activationId);

    const isSidebarCustomisationEnabled = isGateEnabled('atlassian_home_sidebar_customisation')
      ? Boolean(entityAri) // Require valid entityAri, otherwise we abort and disable sidebar customisation
      : false;

    return {
      environment: AggRelayEnvironment,
      query: navigationCustomisationQuery,
      variables: {
        isEnabled: isSidebarCustomisationEnabled,
        entityAri: entityAri,
        ownerAri: userIdToAri(context.user.accountId),
      },
    };
  },
});
