import React from 'react';

import Loadable from '@townsquare/loadable';

import { GoalUpdatesViewSkeleton } from './GoalUpdatesView/GoalUpdatesViewSkeleton';
import { ProjectUpdatesViewSkeleton } from './ProjectUpdatesView/ProjectUpdatesViewSkeleton';
import { SavedViewUpdatesViewSkeleton } from './SavedViewUpdates/SavedViewUpdatesSkeleton';
import { TagUpdatesViewSkeleton } from './TagUpdatesView/TagUpdatesViewSkeleton';
import { TeamUpdatesViewSkeleton } from './TeamUpdatesView/TeamUpdatesViewSkeleton';

export { goalUpdatesResource } from './resources/goalUpdatesResource';
export { projectUpdatesResource } from './resources/projectUpdatesResource';
export { savedViewUpdatesResource } from './resources/savedViewUpdatesResource';
export { stayInLoopResource } from './resources/stayInLoopResource';
export { tagUpdatesResource } from './resources/tagUpdatesResource';
export { tagUUIDRedirectResource } from './resources/tag-uuid-redirect';
export { teamUpdatesResource } from './resources/teamUpdatesResource';
export { LAST_SELECTED_UPDATES_PAGE_KEY } from './types';
export { LastSelectedUpdatesPageRedirect } from './LastSelectedUpdatesPageRedirect';
export { setLastSelectedUpdatesPage } from './utils';

export { ReadingModeLinkButton } from './ReadingMode/ReadingModeLinkButton';

export { convertQueryParamToUpdateStateFilter, getValidUpdateQueryParamFilter } from './components/UpdateStateFilter';

export const HomeView = Loadable({
  loader: () => import(/* webpackChunkName: "HomeView" */ './HomeView'),
  loading: () => null,
});

export const GoalUpdatesView = Loadable({
  loader: () => import(/* webpackChunkName: "GoalUpdatesView" */ './GoalUpdatesView'),
  loading: () => <GoalUpdatesViewSkeleton />,
  delay: 0,
});

export const ProjectUpdatesView = Loadable({
  loader: () => import(/* webpackChunkName: "ProjectUpdatesView" */ './ProjectUpdatesView'),
  loading: () => <ProjectUpdatesViewSkeleton />,
  delay: 0,
});

export const TagUpdatesView = Loadable({
  loader: () => import(/* webpackChunkName: "TagUpdatesView" */ './TagUpdatesView'),
  loading: () => <TagUpdatesViewSkeleton />,
  delay: 0,
});

export const TagUUIDRedirect = Loadable({
  loader: () => import(/* webpackChunkName: "TagUpdatesView" */ './TagUpdatesView/TagUUIDRedirect'),
  loading: () => null,
});

export const TeamUpdatesView = Loadable({
  loader: () => import(/* webpackChunkName: "TeamUpdatesView" */ './TeamUpdatesView'),
  loading: () => <TeamUpdatesViewSkeleton />,
  delay: 0,
});

export const SavedViewUpdates = Loadable({
  loader: () => import(/* webpackChunkName: "SavedViewUpdates" */ './SavedViewUpdates'),
  loading: () => <SavedViewUpdatesViewSkeleton />,
  delay: 0,
});
