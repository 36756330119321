import { validate as uuidValidate } from 'uuid';

import type { ColumnInfo } from '@townsquare/columns/types';
import { filterUndefined } from '@townsquare/filter-type';
import type { SortTypes, SortDirection } from '@townsquare/sort-button';
import { tqlQueryFromInput } from '@townsquare/tql/query';
import { ComparatorOperator } from '@townsquare/tql/types';

import type { GoalSortEnum } from './components/__generated__/DirectoryViewGoalQuery.graphql';
import { DEFAULT_GOAL_SORTS } from './default-constants';

/**
 * Takes a raw sort key from the URL and transforms it to a new sort key.
 * e.g.
 * `NAME-ASC` -> `[NAME_ASC]`
 * `PHASE_ID-ASC,SCORE-ASC` -> `['PHASE_ID_ASC', 'SCORE_ASC']`
 */
export function resolveRawSort(rawSort?: string) {
  if (!rawSort) {
    return;
  }

  if (rawSort.includes(',')) {
    return rawSort.split(',').map(transformSortKey).filter(filterUndefined);
  } else {
    return resolveSorts(transformSortKey(rawSort));
  }
}

export function resolveSorts(primarySort?: GoalSortEnum): GoalSortEnum[] {
  let initialSorts = [primarySort ?? DEFAULT_GOAL_SORTS[0]];
  if (primarySort === 'SCORE_ASC') {
    initialSorts = ['PHASE_ID_ASC', ...initialSorts];
  }
  if (primarySort === 'SCORE_DESC') {
    initialSorts = ['PHASE_ID_DESC', ...initialSorts];
  }

  const sorts = new Set<GoalSortEnum>(initialSorts);

  return Array.from(sorts.add('NAME_ASC'));
}
/**
 * Transforms a legacy sort key to a new sort key.
 *
 * Example transforms:
 * `NAME-ASC` -> `NAME_ASC`
 * `WATCHING-DESC` -> `WATCHING_DESC`
 * `LAST_UPDATED-DESC` -> `LAST_UPDATED_DESC`
 */
export function transformSortKey(key?: string): GoalSortEnum | undefined {
  const [sortKey, sortOrder] = key?.split('-') ?? [];
  if (sortKey && sortOrder) {
    return `${sortKey}_${sortOrder}` as GoalSortEnum;
  }
  return key as GoalSortEnum | undefined;
}

/**
 * Type helpers to convert GraphQL Enums to legacy sort types.
 */
type TransformSortKeyToLegacyKey<S extends SortTypes> = S extends `${infer Name}_ASC`
  ? `${Name}-ASC`
  : S extends `${infer Name}_DESC`
  ? `${Name}-DESC`
  : never;

type ExtractSortKeyName<S extends SortTypes> = S extends `${infer Name}_ASC`
  ? Name
  : S extends `${infer Name}_DESC`
  ? Name
  : never;
/**
 * Maintain backwards compat with old directory sort keys
 * This is important for embeds, and previously shared links.
 *
 * Example transforms:
 * `NAME_ASC` -> `NAME-ASC`
 * `WATCHING_DESC` -> `WATCHING-DESC`
 * `LAST_UPDATED_DESC` -> `LAST_UPDATED-DESC`
 */

export function transformSortKeyToLegacyKey(key: GoalSortEnum): TransformSortKeyToLegacyKey<GoalSortEnum> {
  const sortParts = key.split('_');
  const sortOrder = sortParts.pop() as SortDirection;
  const sortKey = sortParts.join('_') as ExtractSortKeyName<GoalSortEnum>;
  return `${sortKey}-${sortOrder}`;
}

export function getCustomColumnRelayVariables(columns: ColumnInfo[]) {
  const includedCustomFieldUuids = columns
    .filter(column => column.custom === true && uuidValidate(column.id))
    .map(column => column.id);
  return {
    includedCustomFieldUuids,
  };
}

export function getStandardColumnRelayVariables(columns: ColumnInfo[]) {
  return {
    includeFollowerCount: columns.some(column => column.id === 'followerCount'),
    includeFollowing: columns.some(column => column.id === 'following'),
    includeLastUpdated: columns.some(column => column.id === 'lastUpdated'),
    includeStartDate: columns.some(column => column.id === 'startDate'),
    includeOwner: columns.some(column => column.id === 'owner'),
    includeRelatedProjects: columns.some(column => column.id === 'relatedProjects'),
    includeStatus: columns.some(column => column.id === 'status'),
    includeTargetDate: columns.some(column => column.id === 'targetDate'),
    includeTeam: columns.some(column => column.id === 'team'),
    includeTags: columns.some(column => column.id === 'tags'),
  };
}

export const getDefaultGoalTqlQuery = () =>
  tqlQueryFromInput({
    input: [
      {
        fieldName: 'archived',
        fieldValue: false,
        comparator: ComparatorOperator.EQUALS,
      },
    ],
  });
