import FeatureGates from '@atlaskit/feature-gate-js-client';
import { useRef } from 'react';

import { useAtlasKitAnalytics } from '@townsquare/analytics';

import { COHORT_PARAMETER, Cohort, DEFAULT_VALID_EXPERIMENT_COHORTS, NOT_ENROLLED, VARIATION } from './cohorts';
import { type ExperimentNames } from './experiment-keys';

export const useIsExperimentEnabled = (
  experimentName: ExperimentNames,
  variantKey: Cohort = VARIATION,
  validExperimentCohorts: Cohort[] = DEFAULT_VALID_EXPERIMENT_COHORTS,
  fireExperimentExposure = true,
) => {
  if (FeatureGates.initializeCalled()) {
    const cohort = FeatureGates.getExperimentValue(experimentName, COHORT_PARAMETER, NOT_ENROLLED, {
      fireExperimentExposure,
    }) as Cohort;
    const isValid = typeof cohort === 'string' && validExperimentCohorts.includes(cohort);
    return (isValid ? cohort : NOT_ENROLLED) === variantKey;
  }

  return false;
};

export const useIsExperimentEnabledWithoutExposure = (
  experimentName: ExperimentNames,
  variantKey: Cohort = VARIATION,
  validExperimentCohorts: Cohort[] = DEFAULT_VALID_EXPERIMENT_COHORTS,
) => useIsExperimentEnabled(experimentName, variantKey, validExperimentCohorts, false);

export const useExperimentCohort = (
  experimentKey: string,
  defaultCohort: string,
  fireExperimentExposure?: boolean,
  parameterName: string = COHORT_PARAMETER,
) => {
  const { sendOperationalEvent } = useAtlasKitAnalytics();

  if (FeatureGates.initializeCalled()) {
    try {
      return FeatureGates.getExperimentValue(experimentKey, parameterName, defaultCohort, {
        fireExperimentExposure,
      });
    } catch (error: any) {
      sendOperationalEvent({
        action: 'failed',
        actionSubject: 'getFeatureGatesExperimentCohort',
        attributes: {
          error: error ? error.message : 'Could not get experiment cohort value from Feature Gate client',
        },
      });
      return defaultCohort;
    }
  }
  return defaultCohort;
};

export const manuallyLogExperimentExposure = (experimentName: ExperimentNames) =>
  FeatureGates.manuallyLogExperimentExposure(experimentName);

export const useFireExposedEventOnce = (flagKey: string, value: string, shouldFireExposureEvent: boolean) => {
  const { sendTrackEvent } = useAtlasKitAnalytics();
  const hasFired = useRef<boolean>(false);
  return () => {
    if (!hasFired.current && shouldFireExposureEvent) {
      sendTrackEvent({
        actionSubject: 'feature',
        action: 'exposed',
        attributes: {
          flagKey,
          value,
        },
      });
      hasFired.current = true;
    }
  };
};

export * from './experiment-keys';
export { cohorts, COHORT_PARAMETER } from './cohorts';
