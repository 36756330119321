import { captureException } from '@sentry/react';
import type { RequestParameters, Variables } from 'relay-runtime';

import { RequestError } from '@townsquare/error-state/classes';

export const captureErrorSentry = ({
  fetchUrl,
  request,
  variables,
  error,
  extras = {},
}: {
  fetchUrl: string;
  request: RequestParameters;
  variables: Variables;
  error: RequestError;
  extras: Record<string, unknown>;
}) => {
  captureException(error, scope =>
    scope
      .setExtras({
        ...request,
        ...extras,
        requestVariables: variables,
        graphQLUrl: fetchUrl,
      })
      .setTags({
        operationName: request.name,
        atlRequestId: error.extras.atlRequestId,
        atlTraceId: error.extras.atlTraceId,
      })
      .setFingerprint([request.name, String(error.statusCode)]),
  );
};
