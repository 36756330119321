// eslint-disable-next-line @typescript-eslint/ban-types
type RequestExtras = Record<'atlRequestId' | 'atlTraceId' | (string & {}), string | null>;

export class RequestError extends Error {
  name = 'RequestError';
  statusText = '';
  statusCode = 0;
  extras: RequestExtras;
  constructor(statusText: string, statusCode = 0, extras: RequestExtras = { atlRequestId: null, atlTraceId: null }) {
    super(`${statusText} (${statusCode})`);
    this.statusText = statusText;
    this.statusCode = statusCode;
    this.extras = extras;
  }
}
export class GraphQLFetchError extends RequestError {
  name = 'GraphQLFetchError';
}

export class ForbiddenError extends Error {
  name = 'ForbiddenError';
}

export class UnauthorizedError extends Error {
  name = 'UnauthorizedError';
}

export class BadRequestError extends RequestError {
  name = 'BadRequestError';
}

export class NotFound extends Error {
  name = 'NotFound';
}

export class InternalServerError extends Error {
  name = 'InternalServerError';
}

export class RelayRequiredFieldError extends Error {
  name = 'RelayRequiredFieldError';
}
