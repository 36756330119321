import { startOfDay, subDays } from 'date-fns';

import { formatIsoLocalDate, tzDate } from '@townsquare/date-utils';
import { createRelayResource } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';
import { isNavRefreshEnabled } from '@townsquare/stat-sig/nav4';
import { tqlQueryFromInput } from '@townsquare/tql/query';
import { ComparatorOperator, FilterDoc, Operators, SupportedFiltersTypes } from '@townsquare/tql/types';

import ProjectUpdatesAsideViewQuery, {
  type ProjectUpdatesAsideViewQuery as ProjectUpdatesAsideViewQueryType,
} from '../ProjectUpdatesView/__generated__/ProjectUpdatesAsideViewQuery.graphql';

export const projectUpdatesAsideResource = createRelayResource<ProjectUpdatesAsideViewQueryType>({
  getQuery: (_routerContext, resourceContext) => {
    const context = resourceContext as ResourceContext;
    const sidebarStartDate = formatIsoLocalDate(subDays(startOfDay(tzDate()), 7));
    const sidebarEndDate = formatIsoLocalDate(tzDate());

    const doc: FilterDoc = {
      model: [
        {
          model: ['pending', 'in_progress', 'paused'],
          comparator: ComparatorOperator.EQUALS,
          operator: Operators.OR,
          type: SupportedFiltersTypes.PROJECT_STATUS,
        },
      ],
      operator: Operators.AND,
    };

    const ownProjectsTqlQuery = tqlQueryFromInput({
      input: [
        { fieldName: 'owner', fieldValue: context.user.accountId, comparator: ComparatorOperator.EQUALS },
        { join: Operators.OR },
        {
          fieldName: 'contributor',
          fieldValue: context.user.accountId,
          comparator: ComparatorOperator.EQUALS,
        },
        { join: Operators.AND },
        { fieldName: 'archived', fieldValue: false, comparator: ComparatorOperator.EQUALS },
      ],
      doc,
    });

    const newProjectsTqlQuery = tqlQueryFromInput({
      input: [
        { fieldName: 'archived', fieldValue: false, comparator: ComparatorOperator.EQUALS },
        { fieldName: 'creationDate', fieldValue: sidebarStartDate, comparator: ComparatorOperator.GTE },
      ],
    });

    const completedProjectsTqlQuery = tqlQueryFromInput({
      input: [
        { fieldName: 'archived', fieldValue: false, comparator: ComparatorOperator.EQUALS },
        { fieldName: 'phase', fieldValue: 'done', comparator: ComparatorOperator.EQUALS },
        { fieldName: 'targetDate', fieldValue: sidebarStartDate, comparator: ComparatorOperator.GTE },
        { fieldName: 'targetDate', fieldValue: sidebarEndDate, comparator: ComparatorOperator.LTE },
      ],
    });

    return {
      query: ProjectUpdatesAsideViewQuery,
      variables: {
        workspaceUUID: context.workspace?.UUID || '',
        ownProjectsTqlQuery: ownProjectsTqlQuery,
        newProjectsTqlQuery: newProjectsTqlQuery,
        completedProjectsTqlQuery: completedProjectsTqlQuery,
        isNavRefreshEnabled: isNavRefreshEnabled(resourceContext as ResourceContext),
      },
      options: {
        fetchPolicy: 'store-and-network',
      },
    };
  },
});
