import { getConfig } from '@townsquare/config';
import type { UserResponse } from '@townsquare/identity-client';

const NO_USER_ERROR_MESSAGE = 'User needs to be created first.';

export async function remoteAuthCheck(): Promise<{
  isAuthed: boolean;
  isUnauthenticated: boolean;
  isUnauthorised: boolean;
  isNoUser: boolean;
  user: UserResponse | null;
}> {
  try {
    const authCheckPromise = await fetch(getConfig().authCheckUrl);
    const statusCode = authCheckPromise.status;
    const { code, errors, user } = await authCheckPromise.json();

    const isUnauthenticated = statusCode === 401 || code === 401;
    const isUnauthorised = errors !== undefined && errors[0].message === 401;
    const isAuthed = !isUnauthenticated && !isUnauthorised && !errors;
    const isNoUser = errors?.some((e: any) => e.details === NO_USER_ERROR_MESSAGE);

    return {
      isAuthed,
      isUnauthenticated,
      isUnauthorised,
      isNoUser,
      user,
    };
  } catch (e) {
    return {
      isAuthed: false,
      isUnauthenticated: true,
      isUnauthorised: true,
      isNoUser: false,
      user: null,
    };
  }
}
