import { startDateMax, targetDateMin } from '@townsquare/date-utils';
import { createRelayResource } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';
import { isGateEnabled } from '@townsquare/stat-sig/gate';
import { isNavRefreshEnabled } from '@townsquare/stat-sig/nav4';
import { ProjectOrGoalStatusPhase } from '@townsquare/status-summary';
import { tqlQueryFromInput } from '@townsquare/tql/query';
import { ComparatorOperator, FilterDoc, Operators, SupportedFiltersTypes } from '@townsquare/tql/types';

import { isStatusFilterValid } from '../ui/ProjectsAndGoals/utils/filterUtils';
import TopicPageQuery, {
  type TopicPageQuery as TopicPageQueryQueryType,
} from '../ui/__generated__/TopicPageQuery.graphql';
import { PAGE_SIZE } from '../ui/constants';

export const PROJECT_DEFAULT_TIMELINE_START_DATE = targetDateMin;
export const PROJECT_DEFAULT_TIMELINE_END_DATE = startDateMax;

const pausedOrPendingStateDoc = {
  operator: Operators.OR,
  model: [
    {
      type: SupportedFiltersTypes.PROJECT_STATUS,
      model: ['pending', 'paused'],
      comparator: ComparatorOperator.EQUALS,
      operator: Operators.OR,
    },
  ],
};

const getCustomInput = (status?: string) => {
  if (!status || status === ProjectOrGoalStatusPhase.ALL || status === ProjectOrGoalStatusPhase.PAUSED_OR_PENDING) {
    return [];
  }

  // TQL bug where if status_phase = doneabc it will interpret as status_phase=done
  // Hence we specifically check if the urlParam is one of the allowed options, otherwise we set no filters
  return isStatusFilterValid(status)
    ? [{ fieldName: 'status_phase', fieldValue: `'${status}'`, comparator: ComparatorOperator.EQUALS }]
    : [];
};

const getProjectOrGoalDoc = (status?: string) => {
  return status === ProjectOrGoalStatusPhase.PAUSED_OR_PENDING ? pausedOrPendingStateDoc : undefined;
};

export const getTimelineQuery = (
  uuid: string,
  projectTimelineStartDate: string,
  projectTimelineEndDate: string,
  projectStatus?: string,
) => {
  const projectInput: any = getCustomInput(projectStatus);
  const projectDoc: FilterDoc | undefined = getProjectOrGoalDoc(projectStatus);

  const timelineQueryBaseDoc = {
    operator: Operators.OR,
    model: [
      {
        type: 'startDate',
        model: [projectTimelineEndDate],
        comparator: ComparatorOperator.LT,
        operator: Operators.OR,
      },
      {
        type: SupportedFiltersTypes.CREATION_DATE,
        model: [projectTimelineEndDate],
        comparator: ComparatorOperator.LT,
        operator: Operators.OR,
      },
    ],
  };

  return tqlQueryFromInput({
    doc: projectDoc ? [projectDoc, timelineQueryBaseDoc] : timelineQueryBaseDoc,
    input: [
      { fieldName: 'archived', fieldValue: false, comparator: ComparatorOperator.EQUALS },
      { fieldName: 'label', fieldValue: uuid, comparator: ComparatorOperator.EQUALS },
      {
        fieldName: 'targetDate',
        fieldValue: projectTimelineStartDate,
        comparator: ComparatorOperator.GTE,
      },
      ...projectInput,
    ],
  });
};

export const getProjectOrGoalListQuery = (uuid: string, status?: string) => {
  const statusInput: any = getCustomInput(status);
  const projectDoc: FilterDoc | undefined = getProjectOrGoalDoc(status);

  return tqlQueryFromInput({
    doc: projectDoc,
    input: [
      { fieldName: 'archived', fieldValue: false, comparator: ComparatorOperator.EQUALS },
      { fieldName: 'label', fieldValue: uuid, comparator: ComparatorOperator.EQUALS },
      ...statusInput,
    ],
  });
};

export const topicResource = createRelayResource<TopicPageQueryQueryType>({
  getQuery: (routerContext, resourceContext) => {
    const context = resourceContext as ResourceContext;
    const uuid = routerContext.match.params.uuid ?? '';
    const projectStatus = routerContext.query.projectStatus;
    const goalStatus = routerContext.query.goalStatus;
    const projectTimelineStartDate = routerContext.query.projectTimelineStart ?? PROJECT_DEFAULT_TIMELINE_START_DATE;
    const projectTimelineEndDate = routerContext.query.projectTimelineEnd ?? PROJECT_DEFAULT_TIMELINE_END_DATE;

    const projectTagQuery = getProjectOrGoalListQuery(uuid, projectStatus);

    const projectTimelineQuery = getTimelineQuery(
      uuid,
      projectTimelineStartDate,
      projectTimelineEndDate,
      projectStatus,
    );

    const goalTagQuery = getProjectOrGoalListQuery(uuid, goalStatus);

    const learningsByProjectTagQuery = tqlQueryFromInput({
      input: [
        { fieldName: 'projectLabel', fieldValue: uuid, comparator: ComparatorOperator.EQUALS },
        { fieldName: 'type', fieldValue: 'LEARNING', comparator: ComparatorOperator.EQUALS },
      ],
    });

    const helpPointersTqlFilter = tqlQueryFromInput({
      input: [{ fieldName: 'label', fieldValue: uuid, comparator: ComparatorOperator.EQUALS }],
    });

    const tabName = routerContext.match.params.tabName;
    const view = routerContext.query.view;
    const shouldFetchUpdatesTabData = tabName === 'updates';

    // projects and goals tab is the default tab
    const shouldFetchProjectAndGoalsTabData = !tabName || tabName === 'work';
    // if the user has no access to either projects or goals, we fallback to the knowledge tab, so we should fetch this data if we would be showing the projects and goals tab
    const shouldFetchKnowledgeTabData = shouldFetchProjectAndGoalsTabData || tabName === 'knowledge';

    const isTimelineView = view === 'timeline';
    const expandedTopicPageEnabled = isGateEnabled('expanded_topic_page');
    return {
      query: TopicPageQuery,
      variables: {
        tagUuid: uuid,
        workspaceId: context.workspace?.globalId,
        projectFilter: projectTagQuery,
        goalFilter: goalTagQuery,
        learningsFilter: `${learningsByProjectTagQuery}`,
        pageSize: PAGE_SIZE,
        shouldFetchUpdatesTabData,
        shouldFetchKnowledgeTabData,
        shouldFetchProjectAndGoalsTabData,
        projectTimelineQuery,
        isTimelineView,
        helpPointersTqlFilter,
        workspaceUuid: context.workspace?.UUID ?? '',
        expandedTopicPageEnabled,
        isNavRefreshEnabled: isNavRefreshEnabled(resourceContext as ResourceContext),
      },
      cacheKey: 'topic' + tabName,
    };
  },
});
