export function filterUndefined<T>(x: T | undefined): x is Exclude<T, undefined> {
  return x !== undefined;
}

export function filterNull<T>(x: T | null): x is Exclude<T, null> {
  return x !== null;
}

export function filterNullAndUndefined<T>(x: T | null | undefined): x is Exclude<T, null | undefined> {
  return x !== null && x !== undefined;
}

/**
 * @param str a string, maybe
 * @returns if it is a string, excluding empty strings
 */
export function filterFalsyStrings<T extends string>(str: T | null | undefined): str is T {
  return !!str;
}

export function filterNonString(x?: unknown): x is string {
  return typeof x === 'string';
}
