import { createResource } from 'react-resource-router/resources';

import { ResourceContext } from '@townsquare/router';

import { DashboardExperienceResponse } from '../types';

export const dashboardExperienceResource = createResource<DashboardExperienceResponse>({
  getData: async (_, resourceContext) => {
    const { workspace } = resourceContext as ResourceContext;

    if (!workspace) {
      return {
        isOptedIn: false,
        isOptedInToAi: false,
      };
    }
    const { activationId, cloudId } = workspace;
    const response = await fetch(
      `/gateway/api/avp-dashboards/cloudId/${cloudId}/workspaceId/${activationId}/dashboard-experience-status`,
    );

    if (!response.ok) {
      return {
        isOptedIn: false,
        isOptedInToAi: false,
      };
    }

    const data = await response.json();

    return {
      isOptedIn: data.isOptedIn ?? false,
      isOptedInToAi: data.isOptedInToAi ?? false,
    };
  },
  getKey: () => 'dashboard-experience-status-key',
  type: 'DASHBOARD_EXP_STATUS',
  maxAge: 60_000 * 5,
});
