import { getRovoParams } from '@atlaskit/rovo-triggers';
import { createResource } from 'react-resource-router/resources';

import { logMessage } from '@townsquare/logging';
import { HomeResourceContext } from '@townsquare/router';
import { isGateEnabled } from '@townsquare/stat-sig/gate';

import { EntitlementAPIResponse, EntitlementV2APIResponse, RovoEntitlement } from './types';

const ROVO_PRODUCT_HEADER = 'rovo';
const ROVO_EXPERIENCE_ID_HEADER = 'ai-mate';

export const getTraceIdFromResponse = (response: Response | undefined) =>
  response?.headers?.get('x-trace-id') ??
  response?.headers?.get('atl-traceid') ??
  response?.headers?.get('x-b3-traceid') ??
  undefined;

const getRovoEntitlement = async (cloudId: string) => {
  try {
    const response = await fetch('/gateway/api/assist/rovo/v2/entitlement', {
      headers: {
        'X-Cloudid': cloudId ?? '',
      },
    });

    if (!response.ok) {
      const responseErrorMessage = await response.text().catch(() => undefined);
      logMessage('Failed to fetch RovoEntitlement [response not ok]', 'error', {
        cloudId,
        responseStatus: response.status,
        responseTraceId: getTraceIdFromResponse(response),
        responseErrorMessage,
      });

      return {
        isRovoEnabled: false,
        isRovoLLMEnabled: false,
        reason: 'Failed to fetch RovoEntitlement [response not ok]',
      };
    }

    const data: EntitlementV2APIResponse = await response.json();

    return {
      isRovoEnabled: data.isRovoEnabled,
      isRovoLLMEnabled: data.isRovoLLMEnabled,
      reason: data.reason,
    };
  } catch (error) {
    logMessage('Failed to fetch RovoEntitlement [catch block]', 'error', {
      cloudId,
      error,
    });

    return {
      isRovoEnabled: false,
      isRovoLLMEnabled: false,
      reason: 'Failed to fetch RovoEntitlement [catch block]',
    };
  }
};

export const rovoEntitlementResource = createResource<RovoEntitlement>({
  getData: async (_, resourceContext) => {
    const context = resourceContext as HomeResourceContext;
    const rovoParams = getRovoParams();
    // Workspace doesn't always exist on Atlassian Home **TODAY**, if we are rendering chat without a workspace, we need to default to the first cloudId (as they may still have sites)
    const cloudId = rovoParams.cloudId ?? context.collabContext?.rovoWorkspace;

    if (!cloudId) {
      return {
        isRovoEnabled: false,
        isRovoLLMEnabled: false,
        reason: 'No cloudId exists',
      };
    }

    if (isGateEnabled('rovo_use_rovo_llm_enabled_check')) {
      const rovoEntitlement = await getRovoEntitlement(cloudId);
      return rovoEntitlement;
    }

    const response = await fetch('/gateway/api/assist/api/v2/configuration/entitlement', {
      headers: {
        'X-Cloudid': cloudId ?? '',
        'X-Product': ROVO_PRODUCT_HEADER,
        'X-Experience-Id': ROVO_EXPERIENCE_ID_HEADER,
      },
    });

    if (!response.ok) {
      return {
        isRovoEnabled: false,
        isRovoLLMEnabled: false,
        reason: 'Failed to fetch entitlement data',
      };
    }

    const data: EntitlementAPIResponse = await response.json();

    return {
      isRovoEnabled: data.enabled,
      // this is the same as above, for safely rollout new isRovoLLMEnabled check
      isRovoLLMEnabled: data.enabled,
      reason: data.reason,
    };
  },
  getKey: () => 'rovo-entitlement-resource-key',
  type: 'ROVO_ENTITLEMENT',
  maxAge: 60_000 * 30, // 30 minutes cache during the SPA session
});
