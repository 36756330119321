import { captureException } from '@sentry/react';

import { filterNull } from '@townsquare/filter-type';
import { AggRelayEnvironment, createRelayResource } from '@townsquare/relay-utils';
import { type ResourceContext } from '@townsquare/router';
import { useIsExperimentEnabledWithoutExposure, WORK_FEEDS_EXPERIMENT } from '@townsquare/stat-sig';
import { getStringListConfig } from '@townsquare/stat-sig/config';
import { isGateEnabled } from '@townsquare/stat-sig/gate';

import WorkFeedSectionQuery, {
  GraphStoreAtlasHomeSourcesEnum,
  type WorkFeedSectionQuery as WorkFeedSectionQueryType,
} from '../__generated__/WorkFeedSectionQuery.graphql';

export const VALID_DEFAULT_ENABLED_SOURCES = [
  'JIRA_ISSUE_NEAR_OVERDUE',
  'JIRA_ISSUE_OVERDUE',
  'USER_PAGE_NOT_VIEWED_BY_OTHERS',
  'USER_SHOULD_VIEW_SHARED_PAGE',
  'USER_VIEW_PAGE_COMMENTS',
];

const VALID_ENABLED_SOURCES = VALID_DEFAULT_ENABLED_SOURCES.concat([
  'JIRA_EPIC_WITHOUT_PROJECT',
  'JIRA_ISSUE_ASSIGNED',
  'USER_JOIN_FIRST_TEAM',
  'USER_SHOULD_FOLLOW_GOAL',
  'USER_VIEW_ASSIGNED_ISSUE',
  'USER_VIEW_NEGATIVE_GOAL',
  'USER_VIEW_NEGATIVE_PROJECT',
  'USER_VIEW_UPDATED_GOAL',
  'USER_VIEW_UPDATED_PROJECT',
  'USER_VIEW_PAGE_COMMENTS',
  'USER_VIEW_SHARED_VIDEO',
  'USER_VIEW_TAGGED_VIDEO_COMMENT',
  'USER_VIEW_UPDATED_GOAL',
  'USER_VIEW_UPDATED_PRIORITY_ISSUE',
  'USER_VIEW_UPDATED_PROJECT',
]);

const isEnabledSource = (maybeEnabledSource: string): maybeEnabledSource is GraphStoreAtlasHomeSourcesEnum => {
  return VALID_ENABLED_SOURCES.includes(maybeEnabledSource);
};

export const getEnabledSources = () => {
  const unvalidatedEnabledSources = getStringListConfig(
    'townsquare_work_feed_enabled_sources',
    VALID_DEFAULT_ENABLED_SOURCES,
  );

  const validatedEnabledSources = [...new Set(unvalidatedEnabledSources)]
    .map(maybeEnabledSource => {
      if (!isEnabledSource(maybeEnabledSource)) {
        captureException(new Error('Invalid enabled source provided'), { extra: { maybeEnabledSource } });
        return null;
      }

      return maybeEnabledSource;
    })
    .filter(filterNull)
    .sort();

  return validatedEnabledSources;
};

export const workFeedSectionResource = createRelayResource<WorkFeedSectionQueryType>({
  getQuery: (_, resourceContext) => {
    const context = resourceContext as ResourceContext;
    const organisationId = context.workspace?.organisationId;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const isWorkFeedsEnabled = useIsExperimentEnabledWithoutExposure(WORK_FEEDS_EXPERIMENT);
    const isWorkFeedsLoadTestEnabled = isGateEnabled('townsquare_enable_workfeeds_load_test');
    const containerIds = [`ari:cloud:platform::org/${organisationId}`];
    const enabledSources = getEnabledSources();

    return {
      environment: AggRelayEnvironment,
      query: WorkFeedSectionQuery,
      variables: {
        isEnabled: isWorkFeedsEnabled || isWorkFeedsLoadTestEnabled,
        containerIds,
        enabledSources: enabledSources,
      },
    };
  },
});
