import { graphql, readInlineData } from 'react-relay';

import { filterNull } from '@townsquare/filter-type';
import { UserCapabilityType } from '@townsquare/router/types';
import { getRoleFromCapability } from '@townsquare/router/utils';

import { utils_checkUserHasCapabilities$key } from './__generated__/utils_checkUserHasCapabilities.graphql';

export const checkUserHasCapabilities = (
  data: utils_checkUserHasCapabilities$key | null | undefined,
  requirements: UserCapabilityType[],
  allCapabilitiesRequired: boolean,
): boolean => {
  if (requirements.length === 0) {
    return true;
  }

  const workspace = readWorkspace(data);
  if (!workspace || !workspace.userCapabilities || !workspace.userCapabilities.capabilities) {
    return false;
  }

  const userCapabilities = workspace.userCapabilities.capabilities
    .map(item => {
      if (!item || !item.capabilityContainer || !item.capability) {
        return null;
      }
      return getRoleFromCapability(item.capabilityContainer, item.capability);
    })
    .filter(filterNull);

  return allCapabilitiesRequired
    ? requirements.every(capability => userCapabilities.includes(capability))
    : requirements.some(capability => userCapabilities.includes(capability));
};

export const checkUserIsAdminOfAnything = (data: utils_checkUserHasCapabilities$key | null | undefined): boolean => {
  const workspace = readWorkspace(data);
  if (!workspace || !workspace.userCapabilities || !workspace.userCapabilities.capabilities) {
    return false;
  }

  return workspace.userCapabilities.capabilities.some(item => item?.capability === 'ADMINISTER');
};

const readWorkspace = (data: utils_checkUserHasCapabilities$key | null | undefined) =>
  readInlineData<utils_checkUserHasCapabilities$key>(
    graphql`
      fragment utils_checkUserHasCapabilities on WorkspaceSummary @inline {
        userCapabilities {
          capabilities {
            capability
            capabilityContainer
          }
        }
      }
    `,
    data,
  );
