export const FOLLOWERS_ON_PROJECT_EXPERIMENT = 'followers-visible-on-project';

export const ATLAS_JIRA_GET_ATLAS_FREE = 'atlas_jira_get_atlas_free';
export const ATLAS_JIRA_SEARCH_JIRA_ISSSUES = 'atlas_jira_search_jira_issues';
export const ROVO_SEARCH_COMPARE_RESULTS_PASS_THROUGH_RATE = 'rovo-search-compare-results-pass-through-rate';
export const NAVX_UNIVERSAL_CREATE_EXPERIMENT = 'navx_universal_create_experiment';

// https://switcheroo.atlassian.com/ui/gates/f8fc959e-97c8-41df-ae62-078caef95dc0/key/rovo_chat_button_appearance_experiment_userid
export const ROVO_CHAT_BUTTON_APPEARANCE = 'rovo_chat_button_appearance_experiment_userid';

// https://switcheroo.atlassian.com/ui/gates/1705872e-b4eb-4f1d-9c1f-9ac0aca67f3d
export const ENABLE_CREATE_PROJECT_MENU_VISUAL_REFRESH = 'enable_create_project_menu_visual_refresh';

// Nav refresh 'beta' experiments
// https://hello.atlassian.net/wiki/spaces/JNR/pages/3699574590/Rollout+technical+design#Beta
export const NAV_REFRESH_FREE_CUSTOMERS_EXP = 'jira_nav4_beta_free-customers';
export const NAV_REFRESH_NEW_CUSTOMERS_EXP = 'jira_nav4_beta_new-customers';
export const NAV_REFRESH_NEW_CUSTOMERS_X_EXP = 'confluence_jira_nav4_new_customers';

// https://switcheroo.atlassian.com/ui/gates/f1522991-2cd0-4325-9ff7-bb7478251069/key/townsquare_enable_workfeeds_experiment
export const WORK_FEEDS_EXPERIMENT = 'townsquare_enable_workfeeds_experiment';

// Make sure you add your experiment to the list of valid experiments
export const experiments = {
  ATLAS_JIRA_GET_ATLAS_FREE,
  ATLAS_JIRA_SEARCH_JIRA_ISSSUES,
  ENABLE_CREATE_PROJECT_MENU_VISUAL_REFRESH,
  FOLLOWERS_ON_PROJECT_EXPERIMENT,
  ROVO_SEARCH_COMPARE_RESULTS_PASS_THROUGH_RATE,
  NAV_REFRESH_FREE_CUSTOMERS_EXP,
  NAV_REFRESH_NEW_CUSTOMERS_EXP,
  NAV_REFRESH_NEW_CUSTOMERS_X_EXP,
  NAVX_UNIVERSAL_CREATE_EXPERIMENT,
  WORK_FEEDS_EXPERIMENT,
} as const;

export type ExperimentKeys = keyof typeof experiments;
export type ExperimentNames = (typeof experiments)[ExperimentKeys];
