export enum UpdatesPageName {
  projects = 'projects',
  goals = 'goals',
  questions = 'questions',
  tag = 'tag',
  team = 'team',
  yourWork = 'yourwork',
  savedView = 'view',
}

/**
 * Page names / IDs
 */
export const ONBOARDING_PAGE = 'onboarding';
export const ONBOARDING_PRODUCT_OVERVIEW = 'productOverview';
export const HOWTO_PAGE = 'howto';
export const HOME_PAGE = 'home';
export const YOUR_WORK_PAGE = 'yourWork';
export const STATUS_UPDATES_PAGE = 'statusUpdates';
export const UPDATE_LIST_PAGE = 'updateList';
export const PROJECT_FEED_PAGE = 'projectUpdateList';
export const GOAL_FEED_PAGE = 'goalUpdateList';
export const QUESTION_FEED_PAGE = 'questionList';
export const TAG_FEED_PAGE = 'tagUpdateList';
export const RECENTS_PAGE = 'recents';
export const TEAM_FEED_PAGE = 'teamUpdateList';
export const SAVED_VIEW_FEED_PAGE = 'savedViewUpdateList';
export const READING_MODE_PAGE = 'readingMode';
export const PROJECT_DIRECTORY_PAGE = 'channelList';
export const PROJECT_PAGE = 'channel';
export const GOAL_PAGE = 'goal';
export const GOAL_DIRECTORY_PAGE = 'goals';
export const TAGS_DIRECTORY = 'tags';
export const FULL_PAGE_NOTIFICATIONS_PAGE = 'fullPageNotifications';
export const FULL_PAGE_SEARCH_PAGE = 'FullPageSearch';
export const FULL_PAGE_CHAT_PAGE = 'FullPageChat';
export const CREATE_AGENT_PAGE = 'CreateAgent';
export const EDIT_AGENT_PAGE = 'EditAgent';
export const STAFF_DIRECTORY_PAGE = 'directory';
export const PROFILE_PAGE = 'profile-page';
export const TEAM_PROFILE_PAGE = 'team-profile-page';
export const AGENT_PROFILE_PAGE = 'agent-profile-page';
export const KUDOS_PAGE = 'kudos-profile-page';
export const SETTINGS_PAGE = 'settings';
export const TOPIC_PAGE = 'tag';
export const DASHBOARD_PAGE = 'dashboard';
export const DASHBOARD_DIRECTORY_PAGE = 'dashboards-directory';
export const TEAM_HEALTH_PAGE = 'team-health';
export const TEAM_HEALTH_MONITOR_PAGE = 'team-health-monitor';
export const CREATE_KUDOS_PAGE = 'create-kudos-embed';
export const CREATE_KUDOS_OLD_PAGE = 'create-kudos-old-embed';

/**
 * Path prefixes, to be used as part of a longer route-able URL.
 * Mostly used to ensure we keep a whitelist of allowed path prefix
 *
 * WARNING: If you're adding a new path prefix, you'll need to whitelist it below
 * AND add a new global-edge routing rule in the service descriptor.
 */
export const PATH_PREFIX = {
  PROJECT: '/project',
  GOAL: '/goal',
  PEOPLE: '/people',
  LABEL: '/label',
  TOPIC: '/tag',
  AI_TOPIC: '/topic',
  HELP: '/help',
  UPDATES: '/updates',
  FOURZEROTHREE: '/403',
  FORGE_APP: '/app',
  BILLING: '/billing',
  SETTINGS: '/settings',
  HOW: '/how',
  ADMIN: '/admin',
  ACTION: '/action',
  EMBEDS: '/embeds',
  READING_MODE: '/reading',
  KUDOS: '/kudos',
  CREATE_KUDOS_EMBED_OLD: '/give-kudos',
  POPUP_AUTH_COMPLETE: '/atlas-pop-up-auth-complete',
  YOUR_WORK: '/your-work',
  PROJECT_DIRECTORY: '/projects',
  GOAL_DIRECTORY: '/goals',
  ONBOARDING: '/hello',
  NO_ACCESS: '/noaccess',
  TOPICS: '/topics',
  TAGS: '/tags',
  RECENTS: '/recents',
  JIRA_INTEGRATION_READY: '/jira-integration-ready',
  CONFLUENCE: '/wiki',
  JIRA: '/jira',
  JIRA_ISSUES: '/issues',
  JIRA_SECURE: '/secure',
  HEALTH: '/health',
  NOTIFICATIONS: '/notifications',
  FULL_PAGE_SEARCH: '/search',
  FULL_PAGE_CHAT: '/chat',
  APPS: '/apps',
  /**
   * 'as const' is IMPORTANT. DO NOT REMOVE
   * It tells Typescript this 'PATH_PREFIX' object is read only, thus allowing TS to coalesce the object type from
   *   { [ key: string ]: string }
   * into a more specific and useful
   *   { [ key: string ]: '/project' | '/goal' | 'etc' }
   */
} as const;
// Get the value type of PATH_PREFIX, as in: '/project' | '/goal' | 'etc'
export type AllowedPathPrefix = (typeof PATH_PREFIX)[keyof typeof PATH_PREFIX];
// TS type to enforce route path must contain one of the whitelisted prefix above
type AllowedPath = `${AllowedPathPrefix}${string}`;
export type AllowedPathType = '/' | '/*' | AllowedPath;

/**
 * Did you add a new path prefix? Did you read the warning above?
 */
/**
 * Full paths to actual pages
 */
const { READING_MODE, UPDATES, TOPIC, PEOPLE, ONBOARDING, EMBEDS, NOTIFICATIONS } = PATH_PREFIX;

export const HOME_PATH = '/';
export const YOUR_WORK_PATH = PATH_PREFIX.YOUR_WORK;
export const APPS_PATH = PATH_PREFIX.APPS;
export const READING_MODE_PATH: AllowedPath = `${READING_MODE}/:updateType/:inputSource?`;
export const PROJECT_FEED_PATH: AllowedPath = `${UPDATES}/${UpdatesPageName.projects}`;
export const GOAL_FEED_PATH: AllowedPath = `${UPDATES}/${UpdatesPageName.goals}`;
export const QUESTION_FEED_PATH: AllowedPath = `${UPDATES}/${UpdatesPageName.questions}`;
export const TAG_FEED_PATH_PREFIX: AllowedPath = `${UPDATES}/${UpdatesPageName.tag}`;
export const TOPIC_PAGE_PATH_PREFIX = TOPIC;
export const TOPIC_PAGE_PATH_PATTERN: AllowedPath = `${TOPIC}/:uuid/:tabName?/:viewMode?`;
export const RECENTS_PATH: AllowedPath = '/recents';
export const TEAM_FEED_PATH_PREFIX: AllowedPath = `${UPDATES}/${UpdatesPageName.team}`;
export const SAVED_VIEW_FEED_PATH_PREFIX: AllowedPath = `${UPDATES}/${UpdatesPageName.savedView}`;
export const PROJECT_DIRECTORY_PATH = PATH_PREFIX.PROJECT_DIRECTORY;
export const GOAL_DIRECTORY_PATH = PATH_PREFIX.GOAL_DIRECTORY;
export const PEOPLE_DIRECTORY_PATH = PEOPLE;
export const PEOPLE_DIRECTORY_SEARCH_PATH: AllowedPath = `${PEOPLE}/search`;
export const ONBOARDING_PATH = ONBOARDING;
export const ONBOARDING_PRODUCT_OVERVIEW_PATH: AllowedPath = `${ONBOARDING}/product-overview`;
export const NO_ACCESS_PATH = PATH_PREFIX.NO_ACCESS;
export const CREATE_KUDOS_EMBED_OLD_PATH = PATH_PREFIX.CREATE_KUDOS_EMBED_OLD;
export const CREATE_KUDOS_EMBED_PATH: AllowedPath = `${EMBEDS}/give-kudos`;
export const CREATE_KUDOS_PATH: AllowedPath = `${PATH_PREFIX.KUDOS}/give`;
const TEAM_HEALTH_PATH_PREFIX = `${PATH_PREFIX.HEALTH}/team` as const;
export const TEAM_HEALTH_PATH: AllowedPath = `${TEAM_HEALTH_PATH_PREFIX}/:teamId?`;
export const TEAM_HEALTH_MONITOR_PATH: AllowedPath = `${TEAM_HEALTH_PATH_PREFIX}/:teamId/monitor/:teamHealthUuid?`;
export const TEAM_HEALTH_EMBED_PATH: AllowedPath = `${EMBEDS}/team-health/:teamId?/:teamHealthUuid?`;
export const FULL_PAGE_NOTIFICATIONS_PATH: AllowedPath = NOTIFICATIONS;
export const FULL_PAGE_SEARCH_PATH: AllowedPath = '/search';
export const FULL_PAGE_CHAT: AllowedPath = '/chat';
export const CREATE_AGENT: AllowedPath = `${PATH_PREFIX.FULL_PAGE_CHAT}/agents/new`;
export const EDIT_AGENT: AllowedPath = `${PATH_PREFIX.FULL_PAGE_CHAT}/agents/:agentId/edit`;

export const ADMIN_HUB_URL = `${PATH_PREFIX.ADMIN}/s/:cloudId/users/:userId`;

export const TAGS_PATH = PATH_PREFIX.TAGS;
export const TEAM_PATH: AllowedPath = `${PEOPLE}/team`;
export const AGENT_PATH: AllowedPath = `${PEOPLE}/agent`;
export const TEAM_PATH_PATTERN: AllowedPath = `${PEOPLE}/:id`;
export const AGENT_EMBED_PATH: AllowedPath = `${EMBEDS}/people/agent`;
export const POPUP_AUTH_COMPLETE_PATH = PATH_PREFIX.POPUP_AUTH_COMPLETE;

export const JIRA_INTEGRATION_READY_PATH = PATH_PREFIX.JIRA_INTEGRATION_READY;

export const PROJECT_PATH_PREFIX = PATH_PREFIX.PROJECT;
export const GOAL_PATH_PREFIX = PATH_PREFIX.GOAL;
export const LABEL_PATH_PREFIX = PATH_PREFIX.LABEL;
export const HELP_PATH_PREFIX = PATH_PREFIX.HELP;
export const QUESTION_PATH_PREFIX: AllowedPath = `${HELP_PATH_PREFIX}/question`;

export const PROJECT_PATH_PATTERN: AllowedPath = `${PROJECT_PATH_PREFIX}/:id/:tabName?/:resourceId?`;
export const GOAL_PATH_PATTERN: AllowedPath = `${GOAL_PATH_PREFIX}/:id/:tabName?/:resourceId?`;
export const PEOPLE_PATH_PATTERN: AllowedPath = `${PEOPLE_DIRECTORY_PATH}/:id`;
export const PEOPLE_WORK_PATH_PATTERN: AllowedPath = `${PEOPLE_DIRECTORY_PATH}/:id/work`;
export const PEOPLE_KUDOS_PATH_PATTERN: AllowedPath = `${PEOPLE_DIRECTORY_PATH}/kudos/:id`;
export const PEOPLE_TEAM_PATH_PATTERN: AllowedPath = `${PEOPLE_DIRECTORY_PATH}/team/:id`;
export const PEOPLE_AGENT_PATH_PATTERN: AllowedPath = `${PEOPLE_DIRECTORY_PATH}/agent/:id`;

export const NAME_REDIRECT_SUFFIX = 'redirect';

export const CONFLUENCE_CATCH_ALL_PATH: AllowedPath = `${PATH_PREFIX.CONFLUENCE}/*`;
export const JIRA_CATCH_ALL_PATH: AllowedPath = `${PATH_PREFIX.JIRA}/*`;
export const JIRA_ISSUES_CATCH_ALL_PATH: AllowedPath = `${PATH_PREFIX.JIRA_ISSUES}/*`;
export const JIRA_SECURE_CATCH_ALL_PATH: AllowedPath = `${PATH_PREFIX.JIRA_SECURE}/*`;

export const PROJECT_FIELD_SETTINGS_PATH: AllowedPath = `${PATH_PREFIX.SETTINGS}/field/project`;
export const GOAL_FIELD_SETTINGS_PATH: AllowedPath = `${PATH_PREFIX.SETTINGS}/field/goal`;

export const NAME_EMBED_SUFFIX = 'embed';

export const DASHBOARDS_PATH = '/dashboards';

/**
 * This is not an exhaustive list. It is used by
 * views/home/src/LastSelectedUpdatesPageRedirect.tsx
 * to send user to correct tab when navigating "Home"
 */
export const UpdatesPaths = new Map<UpdatesPageName, AllowedPathType>([
  [UpdatesPageName.projects, PROJECT_FEED_PATH],
  [UpdatesPageName.goals, GOAL_FEED_PATH],
  [UpdatesPageName.questions, YOUR_WORK_PATH], // Redirecting questions to your work.
  [UpdatesPageName.yourWork, YOUR_WORK_PATH],
]);

export const NO_WORKSPACE_UPDATES_PATH: AllowedPath = UPDATES;
export const NO_WORKSPACE_PATHS = [
  ONBOARDING_PATH,
  GOAL_DIRECTORY_PATH,
  PROJECT_DIRECTORY_PATH,
  PEOPLE_DIRECTORY_PATH,
  YOUR_WORK_PATH,
  FULL_PAGE_NOTIFICATIONS_PATH,
  FULL_PAGE_CHAT,
  CREATE_AGENT,
  EDIT_AGENT,
  NO_WORKSPACE_UPDATES_PATH,
];
