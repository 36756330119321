import { createRelayResource } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';
import { isNavRefreshEnabled } from '@townsquare/stat-sig/nav4';

import ProjectHeaderRefreshedQuery, {
  type ProjectHeaderRefreshedQuery as ProjectHeaderRefreshedQueryType,
} from '../components/ProjectHeaderRefreshed/__generated__/ProjectHeaderRefreshedQuery.graphql';

export const projectViewHeaderResource = createRelayResource<ProjectHeaderRefreshedQueryType>({
  getQuery: (routerContext, resourceContext) => {
    return {
      query: ProjectHeaderRefreshedQuery,
      variables: {
        key: routerContext.match.params.id ?? '',
        trackViewEvent: 'DIRECT',
        isNavRefreshEnabled: isNavRefreshEnabled(resourceContext as ResourceContext),
      },
    };
  },
});
