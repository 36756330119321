import { AGENT_PROFILE_PAGE, PEOPLE_AGENT_PATH_PATTERN } from '@townsquare/config/routes';
import { createGlobalRoute } from '@townsquare/router/create-route';

export const agentProfileRoute = createGlobalRoute({
  exact: true,
  name: AGENT_PROFILE_PAGE,
  path: PEOPLE_AGENT_PATH_PATTERN,
  subproduct: 'townsquare-people-team-directory',
  layout: 'expanded',
});
