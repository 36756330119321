const TEAM_ARI_PREFIX = 'ari:cloud:identity::team/';
const USER_ARI_PREFIX = 'ari:cloud:identity::user/';

/**
 * Strips the ari prefix from a team ari if the string is the team ari format, otherwise returns the string as is.
 */
export function stripTeamARI(teamIdOrAri: string) {
  return teamIdOrAri?.startsWith(TEAM_ARI_PREFIX) ? teamIdOrAri.replace(TEAM_ARI_PREFIX, '') : teamIdOrAri;
}

/**
 * Strips the ari prefix from a user ari if the string is the user ari format, otherwise returns the string as is.
 */
export function stripUserARI(userIdOrAri: string) {
  return userIdOrAri?.startsWith(USER_ARI_PREFIX) ? userIdOrAri.replace(USER_ARI_PREFIX, '') : userIdOrAri;
}

export function userIdToAri(userId: string) {
  return USER_ARI_PREFIX + userId;
}
