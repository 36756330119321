/**
 * @generated SignedSource<<80d1732ce04bf7e17ab094f148915e37>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
export type navigationCustomisationQuery$variables = {
  entityAri?: string | null;
  isEnabled: boolean;
  ownerAri?: string | null;
};
export type navigationCustomisationQuery$data = {
  readonly settings_navigationCustomisation?: {
    readonly id: string;
    readonly sidebar: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly menuId: string | null;
          readonly visible: boolean | null;
        } | null;
      }> | null;
    } | null;
  } | null;
};
export type navigationCustomisationQuery = {
  response: navigationCustomisationQuery$data;
  variables: navigationCustomisationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "entityAri"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isEnabled"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ownerAri"
},
v3 = [
  {
    "kind": "Variable",
    "name": "entityAri",
    "variableName": "entityAri"
  },
  {
    "kind": "Variable",
    "name": "ownerAri",
    "variableName": "ownerAri"
  }
],
v4 = {
  "kind": "ScalarField",
  "name": "id"
},
v5 = [
  {
    "concreteType": "SettingsMenuItemEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "concreteType": "SettingsMenuItem",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "menuId"
          },
          {
            "kind": "ScalarField",
            "name": "visible"
          },
          {
            "kind": "ScalarField",
            "name": "__typename"
          }
        ]
      },
      {
        "kind": "ScalarField",
        "name": "cursor"
      }
    ]
  },
  {
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "endCursor"
      },
      {
        "kind": "ScalarField",
        "name": "hasNextPage"
      }
    ]
  }
],
v6 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 30
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "name": "navigationCustomisationQuery",
    "selections": [
      {
        "condition": "isEnabled",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": (v3/*: any*/),
            "concreteType": "SettingsNavigationCustomisation",
            "kind": "LinkedField",
            "name": "settings_navigationCustomisation",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": "sidebar",
                "concreteType": "SettingsMenuItemConnection",
                "kind": "LinkedField",
                "name": "__navigationCustomisation__sidebar_connection",
                "plural": false,
                "selections": (v5/*: any*/)
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "navigationCustomisationQuery",
    "selections": [
      {
        "condition": "isEnabled",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": (v3/*: any*/),
            "concreteType": "SettingsNavigationCustomisation",
            "kind": "LinkedField",
            "name": "settings_navigationCustomisation",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "args": (v6/*: any*/),
                "concreteType": "SettingsMenuItemConnection",
                "kind": "LinkedField",
                "name": "sidebar",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": "sidebar(first:30)"
              },
              {
                "args": (v6/*: any*/),
                "handle": "connection",
                "key": "navigationCustomisation__sidebar",
                "kind": "LinkedHandle",
                "name": "sidebar"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "39232db0e72b81159b90e0b45de4eee6",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "settings_navigationCustomisation",
            "sidebar"
          ]
        }
      ]
    },
    "name": "navigationCustomisationQuery",
    "operationKind": "query",
    "text": "query navigationCustomisationQuery(\n  $entityAri: ID\n  $ownerAri: ID\n  $isEnabled: Boolean!\n) {\n  settings_navigationCustomisation(entityAri: $entityAri, ownerAri: $ownerAri) @include(if: $isEnabled) {\n    id\n    sidebar(first: 30) {\n      edges {\n        node {\n          menuId\n          visible\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "50237a0ce02345d113aaab759a51b7f4";

export default node;
