import { APPS_PATH, HOME_PATH } from '@townsquare/config/routes';
import { WAC_CROSSFLOW_PARAMS } from '@townsquare/crossflow';
import { homeHeaderViewResource } from '@townsquare/home-header/resources';
import Loadable from '@townsquare/loadable';
import { createGlobalRoute } from '@townsquare/router/create-route';
import { titanEapCrossFlowQueryResource } from '@townsquare/titan-eap-cross-flow/resource';
import { workFeedSectionResource } from '@townsquare/work-feeds';

export const homeTabRoute = createGlobalRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "hometab-view" */ './components/HomeTabView'),
    loading: () => null,
  }),
  exact: true,
  name: 'hometab',
  path: HOME_PATH,
  layout: 'home',
  resources: [titanEapCrossFlowQueryResource, homeHeaderViewResource, workFeedSectionResource],
  query: [...WAC_CROSSFLOW_PARAMS.map(param => `${param}?`), 'src?', 'cloudId?'],
});

export const appsRoute = createGlobalRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "apps-view" */ './components/AppsView'),
    loading: () => null,
  }),
  exact: true,
  name: 'apps',
  path: APPS_PATH,
  layout: 'home',
  resources: [],
});
