import '@townsquare/logging/instrument';

import { default as FeatureGateClient } from '@atlaskit/feature-gate-js-client';
import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { matchRoute } from 'react-resource-router';

import { prefetchedAuthCheckPromise } from '@townsquare/auth/prefetched-auth-check';
import { getLogInUrl } from '@townsquare/auth-utils';
import { getConfig } from '@townsquare/config';
import { redirect, location } from '@townsquare/facade';
import type { UserResponse } from '@townsquare/identity-client';
import { isEmbedRoute } from '@townsquare/router/primitives';
import { isGateEnabled } from '@townsquare/stat-sig/gate';
import { featureGatesOptions } from '@townsquare/stat-sig/options';
import { createSearchParamsFromCurrentUrl } from '@townsquare/url-utils/search-params';

import { GoalsApp } from './app-entries/Goals';
import { HomeApp } from './app-entries/Home';
import { StudioApp } from './app-entries/Studio/AppEntry';
import { TeamsApp } from './app-entries/Teams';
import { routes } from './router/routes';

const container = document.getElementById('root');
const config = getConfig();
const hacUrl = config.fullUrl;
const ORG_SITE_ROUTE_REGEX = new RegExp(`^/o/(?<orgIdentifier>[0-9a-zA-Z-]+)(/s/(?<siteIdentifier>[a-zA-Z0-9%+-]+))*`);
const SITE_IDENTIFIER_REGEX = new RegExp(`/s/(?<siteIdentifier>[a-zA-Z0-9%+-]+)*`);

type HandleAuthResponse = { isRedirecting: false; user: UserResponse } | { isRedirecting: true; user: null };

async function handleAuth(): Promise<HandleAuthResponse> {
  try {
    const { isAuthed, isUnauthenticated, isUnauthorised, isNoUser, user } = await prefetchedAuthCheckPromise;

    if (isAuthed) {
      // User is authenticated
      if (user) {
        return { isRedirecting: false, user };
      } else {
        redirect(getLogInUrl());
      }
    } else if (isUnauthorised) {
      const redirectUrl = new URL(hacUrl);
      if (isNoUser && location().pathname !== redirectUrl.pathname) {
        redirect(hacUrl);
      }
    } else if (isUnauthenticated) {
      redirect(getLogInUrl());
    } else {
      // Error code other than 401
      redirect(hacUrl);
    }

    return { isRedirecting: true, user: null };
  } catch (e) {
    redirect(hacUrl);
    return { isRedirecting: true, user: null };
  }
}

function rovoRedirect() {
  if (typeof window !== 'undefined') {
    const config = getConfig();
    if (['rovo.atlassian.com', 'rovo.stg.atlassian.com'].includes(window.location.hostname)) {
      location().replace(config.homeUrl + '/rovo');
    }
  }
}

rovoRedirect();

function reactRender(node: React.ReactNode) {
  if (isGateEnabled('townsquare_react18_render')) {
    const root = createRoot(container!);
    root.render(node);
  } else {
    ReactDOM.render(node as Parameters<typeof ReactDOM.render>[0], container);
  }
}

function renderHome() {
  reactRender(<HomeApp />);
}

function getSiteIdentifier() {
  const match = SITE_IDENTIFIER_REGEX.exec(location().pathname);
  return match?.groups?.siteIdentifier;
}

async function render() {
  const urlSearch = Object.fromEntries(createSearchParamsFromCurrentUrl());
  const matchedRoute = matchRoute(routes, window.location.pathname, urlSearch);

  /**
   * If we are trying to load an embed, we want to provide a 'pass-through'
   * As we have special handling in place to deal with third-party cookies & iframe login.
   */
  if (isEmbedRoute(matchedRoute?.route)) {
    return renderHome();
  }

  const { isRedirecting, user } = await handleAuth();

  if (isRedirecting) {
    return;
  }

  try {
    await FeatureGateClient.initialize(featureGatesOptions, {
      // Need at least one identifier to init statsig
      atlassianAccountId: user.account_id,
      tenantId: getSiteIdentifier() ?? undefined,
    });
  } catch (e) {
    //
  }

  const pathname = location().pathname.replace(ORG_SITE_ROUTE_REGEX, '');
  if (pathname.startsWith('/goal') && isGateEnabled('atlas_goals_app_enabled')) {
    reactRender(<GoalsApp />);
  } else if (pathname.startsWith('/people') && isGateEnabled('atlas_teams_app_enabled')) {
    reactRender(<TeamsApp />);
  } else if (pathname.startsWith('/studio')) {
    let tenantContext: { data?: { bootstrap?: { orgId?: string } } } = {};

    try {
      const tenantContextResponse = await fetch('/gateway/api/watermelon/graphql', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          accept: 'application/json',
        },
        body: JSON.stringify({
          query: 'query TenantContextQuery { bootstrap { orgId } }',
        }),
      });
      tenantContext = await tenantContextResponse.json();
    } catch (e) {
      // don't care, just continue as normal
    }

    try {
      await FeatureGateClient.updateUser(featureGatesOptions, {
        atlassianOrgId: tenantContext.data?.bootstrap?.orgId,
        atlassianAccountId: user.account_id,
        tenantId: getSiteIdentifier() ?? undefined,
      });
    } catch (e) {
      // don't care, there's nothing we can do if it fails to resolve org id
    }

    if (isGateEnabled('enable_studio_route_in_home')) {
      reactRender(<StudioApp />);
    } else {
      renderHome();
    }
  } else {
    renderHome();
  }
}

render();
