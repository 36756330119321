import { PEOPLE_DIRECTORY_PATH, PROFILE_PAGE } from '@townsquare/config/routes';
import { createGlobalRoute } from '@townsquare/router/create-route';

export const profileRoute = createGlobalRoute({
  exact: true,
  name: PROFILE_PAGE,
  path: `${PEOPLE_DIRECTORY_PATH}/:id/(work)?`,
  subproduct: 'townsquare-people-team-directory',
  layout: 'expanded',
});
