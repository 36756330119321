import { createRelayResource } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';
import { isNavRefreshEnabled } from '@townsquare/stat-sig/nav4';

import GoalViewHeaderQuery, {
  type GoalHeaderRefreshedQuery as GoalHeaderRefreshedQueryType,
} from '../components/GoalHeaderRefreshed/__generated__/GoalHeaderRefreshedQuery.graphql';

export const goalViewHeaderResource = createRelayResource<GoalHeaderRefreshedQueryType>({
  getQuery: (routerContext, resourceContext) => {
    return {
      query: GoalViewHeaderQuery,
      variables: {
        key: routerContext.match.params.id ?? '',
        trackViewEvent: 'DIRECT',
        isNavRefreshEnabled: isNavRefreshEnabled(resourceContext as ResourceContext),
      },
    };
  },
});
