import { Inline, media, xcss } from '@atlaskit/primitives';
import React from 'react';

const styles = xcss({
  gridArea: 'actions',
  [media.above.xxs]: {
    marginBlockStart: 'space.200',
  },
  [media.above.sm]: {
    marginInlineStart: 'auto',
    marginBlockStart: 'unset',
  },
});

export const Actions = ({ children }: React.PropsWithChildren<unknown>) => {
  return (
    <Inline space="space.100" xcss={styles} shouldWrap>
      {children}
    </Inline>
  );
};
