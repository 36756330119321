import FeatureGates from '@atlaskit/feature-gate-js-client';
import { type PropsWithChildren } from 'react';

import { GateNames } from './gate-keys';

export const useFeatureGate = (gateName: GateNames): boolean => {
  // Need to update a bunch of tests first - https://product-fabric.atlassian.net/browse/TC-8019
  // if (!FeatureGates.initializeCompleted()) {
  //   throw new Error(
  //     'useFeatureGate was called with Feature gates not initialized! Your component is probably rendered outside of <FeatureGatesInitialization>',
  //   );
  // }
  return FeatureGates.checkGate(gateName);
};

export function FeatureGate(props: PropsWithChildren<{ gateName: GateNames }>) {
  const isGateEnabled = useFeatureGate(props.gateName);
  return isGateEnabled ? props.children : null;
}

export function isGateEnabled(gateName: GateNames): boolean {
  // Need to update a bunch of tests first - https://product-fabric.atlassian.net/browse/TC-8019
  // if (!FeatureGates.initializeCompleted()) {
  //   throw new Error(
  //     'isGateEnabled was called with Feature gates not initialized! Your component is probably rendered outside of <FeatureGatesInitialization>',
  //   );
  // }

  return FeatureGates.checkGate(gateName);
}
