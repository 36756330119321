import FeatureGates from '@atlaskit/feature-gate-js-client';

import {
  hasAdminOptedInNavRefresh,
  hasOptedOutNavRefresh,
} from '@townsquare/nav-refresh-opt-out/has-opted-out-nav-refresh';
import { createSearchParamsFromCurrentUrl } from '@townsquare/url-utils/search-params';

import { isGateEnabled } from '../Gate';
import {
  NAV_REFRESH_FREE_CUSTOMERS_EXP,
  NAV_REFRESH_NEW_CUSTOMERS_EXP,
  NAV_REFRESH_NEW_CUSTOMERS_X_EXP,
} from '../experiment-keys';

import { BETA_EXPERIMENT_ENABLE_KEY, Nav4CheckProp } from './type';

const GRIFFIN_NAV_OPT_OUT = 'nav4-optout';

const isQueryParamOptOut = ((): boolean => {
  const param = createSearchParamsFromCurrentUrl().get(GRIFFIN_NAV_OPT_OUT);

  try {
    if (param && JSON.parse(param) === true) {
      return true;
    }
  } catch {
    // Don't let parsing blow up the app.
  }
  return false;
})();

const hasOptedOut = () => hasOptedOutNavRefresh() || isQueryParamOptOut;

const shouldEnableNavRefreshForDogfooding = () => isGateEnabled('jira_nav4_dogfooding') && !hasOptedOut();
const shouldEnableNavRefreshForEAP = () => isGateEnabled('jira_nav4_eap') && !hasOptedOut();
const shouldEnableNavRefreshForBeta = () =>
  (isGateEnabled('jira_nav4_beta_existing-customers') && !hasOptedOut()) ||
  isGateEnabled('jira_nav4_beta_sandboxes') ||
  FeatureGates.getExperimentValue(NAV_REFRESH_FREE_CUSTOMERS_EXP, BETA_EXPERIMENT_ENABLE_KEY, false) ||
  FeatureGates.getExperimentValue(NAV_REFRESH_NEW_CUSTOMERS_EXP, BETA_EXPERIMENT_ENABLE_KEY, false) ||
  FeatureGates.getExperimentValue(NAV_REFRESH_NEW_CUSTOMERS_X_EXP, BETA_EXPERIMENT_ENABLE_KEY, false);

const shouldEnableNavRefreshForGA = (props: Nav4CheckProp) => {
  if (isGateEnabled('jira_nav4_ga_phase-3')) {
    return true;
  }

  if (isGateEnabled('jira_nav4_ga_phase-2')) {
    return !hasOptedOut();
  }

  if (isGateEnabled('jira_nav4_ga_phase-1')) {
    if (props.isNav4Admin && hasAdminOptedInNavRefresh()) {
      return true;
    }
    return props?.isSiteEnrolledToNav4 && !hasOptedOut();
  }

  if (isGateEnabled('jira_nav4_ga_sandboxes')) {
    return hasAdminOptedInNavRefresh();
  }

  return false;
};

export const shouldEnableNavRefresh = (props: Nav4CheckProp) => {
  // Master flag. If disabled = dont show anything
  if (!isGateEnabled('jira_nav4') || !isGateEnabled('atlassian_home_nav4')) {
    return false;
  }

  return Boolean(
    shouldEnableNavRefreshForDogfooding() ||
      shouldEnableNavRefreshForEAP() ||
      shouldEnableNavRefreshForBeta() ||
      shouldEnableNavRefreshForGA(props),
  );
};
