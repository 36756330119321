import { createRelayResource } from '@townsquare/relay-utils';
import type { ResourceContext } from '@townsquare/router';
import { isGateEnabled } from '@townsquare/stat-sig/gate';

import useUserHasCapabilitiesQuery, {
  useUserHasCapabilitiesQuery as useUserHasCapabilitiesQueryType,
} from './__generated__/useUserHasCapabilitiesQuery.graphql';

export const capabilityProviderResource = createRelayResource<useUserHasCapabilitiesQueryType>({
  getQuery: (_, resourceContext) => {
    const context = resourceContext as ResourceContext;
    const isValidWorkspace =
      isGateEnabled('atlas-enable-capability-router') &&
      Boolean(context.workspace?.cloudId) &&
      Boolean(context.workspace?.organisationId);

    return {
      query: useUserHasCapabilitiesQuery,
      variables: {
        isValidWorkspace,
        context: {
          orgId: context.workspace?.organisationId ?? '',
          cloudId: context.workspace?.cloudId,
        },
      },
      maxAge: 60_000 * 30, // 30 minutes cache during the SPA session
    };
  },
});
