import { Box, xcss } from '@atlaskit/primitives';
import React from 'react';

const styles = xcss({
  gridArea: 'breadcrumbs',
  marginBlockEnd: 'space.050',
  minWidth: '0',
});

export const Breadcrumbs = ({ children }: React.PropsWithChildren<unknown>) => {
  return <Box xcss={styles}>{children}</Box>;
};
