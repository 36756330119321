import * as Sentry from '@sentry/browser';

import { isUsingDefaultUrl } from '@townsquare/auth-utils';
import { getEnv, getReleaseVersion } from '@townsquare/config';

import { sentryFilterEvents, filterBreadcrumb } from './utils';

const sentryDSN = 'https://8fc705dbdd6642369d8cd2dd00b97219@o55978.ingest.sentry.io/5988862';

const isProductionBuild = !!process.env.__BUILD_PROD__;
// @ts-expect-error - BIFROST_OVERRIDE_VERSION is not defined in the global scope
const isBifrostOverrideActive = window?.BIFROST_OVERRIDE_VERSION;
// eslint-disable-next-line no-restricted-syntax
const enableSentryQueryParam = new URLSearchParams(window.location.search).get('enableSentry');
const isSentryEnabled = isProductionBuild && isBifrostOverrideActive === undefined;

if (isUsingDefaultUrl() && (enableSentryQueryParam === 'true' || isSentryEnabled)) {
  // Only enable sentry when Atlas is accessed from the default URL
  Sentry.init({
    dsn: sentryDSN,
    environment: getEnv(),
    // We dont want sentry testing to pollute our release history
    release: enableSentryQueryParam === 'true' ? 'sentry-test' : getReleaseVersion(),
    sampleRate: 1,
    maxBreadcrumbs: 20, // Only send 20 most recent breadcrumbs
    beforeSend: sentryFilterEvents,
    beforeBreadcrumb: filterBreadcrumb,
    ignoreErrors: [
      // A benign error, see https://stackoverflow.com/a/50387233/2645305
      'ResizeObserver loop limit exceeded',
      /ResizeObserver loop completed with undelivered notifications/,
      // Happens when trying to load an async chunk that can't be found and is redirected to the 404 page
      // The error isn't very actionable and we don't want to be alerted for it
      // Sentry even has a default filter to ignore these errors, but some browsers emit with a different name
      // https://github.com/getsentry/sentry-javascript/issues/6945
      `Unexpected token '<'`,
      // Usually network issues (internet dropout, VPN troubles, etc)
      'Failed to fetch',
      // These are always tricky and hard to track down
      // In general, they do not have customer impact but we can remove if we get reports of issues getting hidden by this
      '<unknown>',
    ],
  });
}
