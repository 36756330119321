import { Route } from 'react-resource-router';

import type {
  AccessControlCapability,
  CapabilityContainer,
} from './CapabilityRouter/__generated__/CapabilityRouterFragment.graphql';
import type { RouteWithOutlet, RouteWithSubproduct, UserCapabilityType } from './types';

export function isRouteWithOutlet(route: Route): route is RouteWithOutlet {
  return Object.prototype.hasOwnProperty.call(route, 'type');
}

export function isRouteWithSubproduct(route: Route): route is RouteWithSubproduct {
  return Object.prototype.hasOwnProperty.call(route, 'subproduct');
}

export const getRoleFromCapability = (
  capabilityContainer: CapabilityContainer,
  capability: AccessControlCapability,
): UserCapabilityType => {
  return `${capabilityContainer.toLowerCase()}:${capability.toLowerCase()}` as UserCapabilityType;
};
