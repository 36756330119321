import { PEOPLE_TEAM_PATH_PATTERN, TEAM_PROFILE_PAGE } from '@townsquare/config/routes';
import { createGlobalRoute } from '@townsquare/router/create-route';

export const teamProfileRoute = createGlobalRoute({
  exact: true,
  name: TEAM_PROFILE_PAGE,
  path: PEOPLE_TEAM_PATH_PATTERN,
  subproduct: 'townsquare-people-team-directory',
  layout: 'expanded',
});
