import Loadable from '@townsquare/loadable';
import { createGlobalRoute } from '@townsquare/router/create-route';

export const notFoundRoute = createGlobalRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "not-found-view" */ './FourOhFourPage'),
    loading: () => null,
  }),
  exact: true,
  name: 'not-found',
  path: '',
});
