import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import ReactLoadable from 'react-loadable';

const Loadable = <Props, Exports extends object>(options: ReactLoadable.Options<Props, Exports>) => {
  const LoadingComponent = options.loading;
  options.loading = props => {
    return <UFOLoadHold name="loadable-hold">{LoadingComponent ? <LoadingComponent {...props} /> : null}</UFOLoadHold>;
  };
  return ReactLoadable(options);
};

export default Loadable;
