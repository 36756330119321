import { token } from '@atlaskit/tokens';
import React from 'react';
import styled from 'styled-components';

import {
  AvatarLoadingSkeleton,
  LoadingSkeleton,
  LoadingSkeletonContainer,
  SquareLoadingSkeleton,
} from '@townsquare/loading-skeleton';
import { useIsNavRefreshEnabled } from '@townsquare/stat-sig/nav4';

import { Card, CardRefreshed, QuestionOrReplyCard } from './Card';
import { UpdateCard } from './UpdateCard';

export const UpdateCardBodySkeleton = () => (
  <>
    <LoadingSkeletonContainer>
      {/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
      <div style={{ width: '260px' }}>
        <LoadingSkeletonContainer>
          <AvatarLoadingSkeleton sideLengthPx={24} marginRightPx={2} />
          <LoadingSkeleton height="16px" width="218px" />
        </LoadingSkeletonContainer>
      </div>
      <div>
        <LoadingSkeleton height="24px" width="70px" />
      </div>
    </LoadingSkeletonContainer>
    <LoadingSkeletonContainer margin="24px 0 0 0">
      <LoadingSkeleton height="16px" width="550px" />
    </LoadingSkeletonContainer>
    <LoadingSkeletonContainer margin="4px 0 0 0">
      <LoadingSkeleton height="16px" width="550px" />
    </LoadingSkeletonContainer>
    <LoadingSkeletonContainer margin="4px 0 0 0">
      <LoadingSkeleton height="16px" width="380px" />
    </LoadingSkeletonContainer>
  </>
);

export const UpdateCardSkeleton = () => {
  const homeVisualRefreshEnabled = useIsNavRefreshEnabled();

  const CardElement = homeVisualRefreshEnabled ? CardRefreshed : Card;

  return (
    <UpdateCard>
      <CardElement>
        <UpdateCardBodySkeleton />
      </CardElement>
    </UpdateCard>
  );
};

const EntitySkeletonWrapper = styled.div({
  display: 'flex',
  marginBottom: token('space.100'),
});

export const UpdateCardHeaderSkeleton = () => (
  <EntitySkeletonWrapper>
    <SquareLoadingSkeleton sideLengthPx={32} marginRightPx={8} />
    <div>
      <LoadingSkeleton width="50px" height="12px" marginBottomPx={4} />
      <LoadingSkeleton width="180px" height="16px" />
    </div>
  </EntitySkeletonWrapper>
);

export const QuestionFeedCardSkeleton = () => (
  <UpdateCard>
    <QuestionOrReplyCard>
      <LoadingSkeletonContainer>
        {/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
        <div style={{ width: '260px' }}>
          <LoadingSkeletonContainer>
            <LoadingSkeleton height="16px" width="218px" />
          </LoadingSkeletonContainer>
        </div>
      </LoadingSkeletonContainer>
      <LoadingSkeletonContainer margin="24px 0 0 0">
        <LoadingSkeleton height="16px" width="550px" />
      </LoadingSkeletonContainer>
      <LoadingSkeletonContainer margin="4px 0 0 0">
        <LoadingSkeleton height="16px" width="550px" />
      </LoadingSkeletonContainer>
      <LoadingSkeletonContainer margin="4px 0 0 0">
        <LoadingSkeleton height="16px" width="380px" />
      </LoadingSkeletonContainer>
    </QuestionOrReplyCard>
    <QuestionOrReplyCard>
      <ReplySkeleton />
    </QuestionOrReplyCard>
  </UpdateCard>
);

export const ReplySkeleton = () => {
  return (
    <LoadingSkeletonContainer>
      {/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
      <div style={{ width: '260px' }}>
        <LoadingSkeletonContainer>
          <AvatarLoadingSkeleton sideLengthPx={24} marginRightPx={2} />
          <LoadingSkeleton height="16px" width="218px" />
        </LoadingSkeletonContainer>
      </div>
    </LoadingSkeletonContainer>
  );
};

export const QuestionFeedHeaderSkeleton = () => (
  <EntitySkeletonWrapper>
    <LoadingSkeletonContainer>
      {/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
      <div style={{ width: '260px' }}>
        <LoadingSkeletonContainer>
          <AvatarLoadingSkeleton sideLengthPx={24} marginRightPx={2} />
          <LoadingSkeleton height="16px" width="218px" />
        </LoadingSkeletonContainer>
      </div>
    </LoadingSkeletonContainer>
  </EntitySkeletonWrapper>
);

export const UpdateCardSkeletonWithReply = () => {
  const homeVisualRefreshEnabled = useIsNavRefreshEnabled();

  const CardElement = homeVisualRefreshEnabled ? CardRefreshed : Card;

  return (
    <UpdateCard>
      <CardElement>
        <UpdateCardBodySkeleton />
      </CardElement>
      <QuestionOrReplyCard>
        <ReplySkeleton />
      </QuestionOrReplyCard>
    </UpdateCard>
  );
};
