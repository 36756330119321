import type { MatchParams } from 'react-resource-router';

import type { ToAttributes } from '@townsquare/router/primitives';
import type { HomepageRouteType } from '@townsquare/router/route-types';

export enum ContentTabName {
  ABOUT = 'about',
  UPDATES = 'updates',
  LEARNINGS = 'learnings',
  RISKS = 'risks',
  DECISIONS = 'decisions',
  APP = 'app',
  PROJECTS = 'projects',
  JIRA = 'jira',
}

export interface TabData {
  id: string;
  headingComponent: JSX.Element;
  bodyComponent: JSX.Element;
}

export interface DefaultTabs {
  aboutTab: TabData & { id: ContentTabName.ABOUT };
  updatesTab: TabData & { id: ContentTabName.UPDATES };
}

export type TabEntityType = 'project' | 'goal';

export type ContentTabsProps = {
  params: MatchParams;
  tabType: TabEntityType;
  defaultTabs: DefaultTabs;
  additionalTabs?: TabData[];
  isWatching: boolean;
  hasPendingUpdate: boolean;
  routeTo: HomepageRouteType;
  routeAttributes: ToAttributes;
  forgeAppContext: ForgeTabsAppContext;
  onMobile?: boolean;
};

export interface ForgeTabsAppContext {
  entityType: TabEntityType;
  entityId: string;
  entityKey: string;
}
