import { localStorageFacade } from '@townsquare/facade';

import { NAV_REFRESH_ADMIN_OPT_IN, NAV_REFRESH_OPT_OUT_KEY } from './key';

/**
 * Split out so we can test this easily and avoid mocking out relay environment
 */
// Use local storage cache to know if user opted out of nav refresh to avoid awaiting a request
export const hasOptedOutNavRefresh = () => !!localStorageFacade.getItem(NAV_REFRESH_OPT_OUT_KEY);

export const hasAdminOptedInNavRefresh = () => !!localStorageFacade.getItem(NAV_REFRESH_ADMIN_OPT_IN);
