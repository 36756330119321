import { loadQuery, LoadQueryOptions, PreloadedQuery } from 'react-relay';
import { type RouterContext, type RouterDataContext, useQueryParam } from 'react-resource-router';
import {
  createResource,
  type ResourceStoreContext,
  type RouteResource,
  useResource,
} from 'react-resource-router/resources';
import type { ConcreteRequest, OperationType, VariablesOf, Environment } from 'relay-runtime';
import { v4 as uuidv4 } from 'uuid';

import { useFeatureFlag } from '@townsquare/feature-flags';
import { GetValueOptions, SupportedFlagTypes } from '@townsquare/feature-flags/types';
import { logMessage } from '@townsquare/logging';
import { useFeatureGate } from '@townsquare/stat-sig/gate';

import { getRelayEnvironment } from './RelayEnvironment';

export const RELAY_RESOURCE_TYPE = 'RELAY_RESOURCE_TYPE';

export interface RelayResourceConfig<TQuery extends OperationType> {
  query: ConcreteRequest;
  variables?: VariablesOf<TQuery>;
  options?: LoadQueryOptions;
  cacheKey?: string;
  environment?: Environment;
}

type GetQueryFunction<TQuery extends OperationType> = (
  routerContext: RouterContext | RouterDataContext,
  resourceStoreContext: ResourceStoreContext,
) => RelayResourceConfig<TQuery>;

function createCacheKey<TQuery extends OperationType>(query: ConcreteRequest, variables?: VariablesOf<TQuery>) {
  const queryId = 'cacheID' in query.params ? query.params.cacheID : query.params.id;
  return `relay-${queryId}-${query.params.name}-${JSON.stringify(variables ?? {})}`;
}

type RefData = { key: string; hasData: boolean };
const keyToDataMap = new Map<string, RefData>();

export const createRelayResource = <TQuery extends OperationType>({
  getQuery,
  maxAge,
}: {
  getQuery: GetQueryFunction<TQuery>;
  maxAge?: number;
}): RouteResource<PreloadedQuery<TQuery>> => {
  const fallbackKey = uuidv4();
  return createResource({
    maxAge,
    type: RELAY_RESOURCE_TYPE,
    getKey: (routerContext, resourceStoreContext) => {
      try {
        const { query, variables, cacheKey } = getQuery(routerContext, resourceStoreContext);

        const key = cacheKey ?? createCacheKey(query, variables);

        if (!keyToDataMap.has(key)) {
          keyToDataMap.set(fallbackKey, {
            key,
            hasData: false,
          });
        }

        return key;
      } catch (error) {
        logMessage('[createRelayResource.getKey] Error creating cache key', 'fatal', { error, fallbackKey });
        return fallbackKey;
      }
    },
    getData: async (routerContext, resourceStoreContext) => {
      const { environment, query, variables, options, cacheKey } = getQuery(routerContext, resourceStoreContext);

      // This is a fallback key that is used to track if the data has been loaded for a getKey call,
      // if a getKey exists in the cache with no data, we log there is a key mismatch
      const key = cacheKey ?? createCacheKey(query, variables);
      const data = keyToDataMap.get(fallbackKey);
      if (data && !data.hasData) {
        if (data.key !== key) {
          // If you are seeing this message in Sentry, this may mean users are seeing white screens. As we are fetching data based on a cache key that doesnt exist.
          logMessage('[createRelayResource.getData] Key mismatch', 'fatal', {
            key,
          });
        } else {
          keyToDataMap.set(fallbackKey, {
            key,
            hasData: true,
          });
        }
      }

      const queryReference = loadQuery<TQuery>(
        // resourceStoreContext.environment is used for testing purposes, the main app context does not provide an environment in its resourceStoreContext
        (resourceStoreContext as any).__environment__ ?? environment ?? getRelayEnvironment(),
        query,
        variables ?? {},
        options,
      );

      return queryReference;
    },
  });
};

export const useRelayResource = <TQuery extends OperationType>(resource: RouteResource<PreloadedQuery<TQuery>>) => {
  const { data: queryReference } = useResource(resource);
  return queryReference;
};

export const useResourceVariables = <TQuery extends OperationType>(resource: RouteResource<PreloadedQuery<TQuery>>) => {
  const { data: queryReference } = useResource(resource);
  return queryReference?.variables;
};

export const useRelayFeatureFlag = <T extends SupportedFlagTypes>(
  flagKey: string,
  defaultValue: T,
  options?: GetValueOptions<T>,
) => {
  const flagValue = useFeatureFlag(flagKey, defaultValue, options);
  const [relay] = useQueryParam('relay');
  return Boolean(Number(relay)) && flagValue;
};

export const useRelayFeatureGate = (...args: Parameters<typeof useFeatureGate>) => {
  const flagValue = useFeatureGate(...args);
  const [relay] = useQueryParam('relay');
  return Boolean(Number(relay)) && flagValue;
};
