import { createRelayResource } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';
import { isNavRefreshEnabled } from '@townsquare/stat-sig/nav4';

import SavedViewUpdatesQuery, {
  type SavedViewUpdatesQuery as SavedViewUpdatesQueryType,
} from '../SavedViewUpdates/__generated__/SavedViewUpdatesQuery.graphql';

export const savedViewUpdatesResource = createRelayResource<SavedViewUpdatesQueryType>({
  getQuery: (routerContext, resourceContext) => {
    const context = resourceContext as ResourceContext;

    return {
      query: SavedViewUpdatesQuery,
      variables: {
        workspaceUuid: context.workspace?.UUID || '',
        viewUuid: routerContext.match.params.uuid || '',
        isNavRefreshEnabled: isNavRefreshEnabled(resourceContext as ResourceContext),
      },
      options: {
        fetchPolicy: 'store-and-network',
      },
    };
  },
});
