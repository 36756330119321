import { SITE_IDENTIFIER_PLACEHOLDER } from '@townsquare/config';
import { localStorageFacade, location } from '@townsquare/facade';

const SITE_ROUTE_REGEX = new RegExp(`^/o/(?<orgIdentifier>[0-9a-zA-Z-]+)/s/(?<siteIdentifier>[a-zA-Z0-9%+-]+).*$`);

type FetchUrlConfig = {
  defaultUrl: string;
  multiRegionUrl?: string;
  cloudId?: string;
};

export const resolveFetchUrl = ({ defaultUrl, multiRegionUrl, cloudId }: FetchUrlConfig) => {
  const match = SITE_ROUTE_REGEX.exec(location().pathname);

  if (multiRegionUrl) {
    localStorageFacade.setItem('multiRegionRoutingEnabled', 'true');

    // If we know we're in a sited context, always use the sharded url
    const siteIdentifier = match?.groups?.siteIdentifier;

    if (siteIdentifier) {
      return multiRegionUrl.replace(SITE_IDENTIFIER_PLACEHOLDER, encodeURI(siteIdentifier));
    } else if (cloudId) {
      return multiRegionUrl.replace(SITE_IDENTIFIER_PLACEHOLDER, encodeURI(cloudId));
    }
  }

  return defaultUrl;
};
