import { useEffect, useState } from 'react';
import { fetchQuery, graphql, useClientQuery, useRelayEnvironment, type Environment } from 'react-relay';

import { useGoalWorkspace, useProjectWorkspace } from '@townsquare/collab-context';
import { filterNull } from '@townsquare/filter-type';
import { useOrgContextStore } from '@townsquare/org-context';
import { UserCapabilityType } from '@townsquare/router/types';
import { useFeatureGate } from '@townsquare/stat-sig/gate';

import {
  useUserHasCapabilitiesQuery,
  useUserHasCapabilitiesQuery$data,
} from './__generated__/useUserHasCapabilitiesQuery.graphql';
import { checkUserHasCapabilities } from './utils';

export const userCapabilitiesQuery = graphql`
  query useUserHasCapabilitiesQuery($context: OrganizationContextInput!, $isValidWorkspace: Boolean!) {
    bootstrap(organizationContext: $context) @include(if: $isValidWorkspace) {
      workspaces {
        cloudId
        ...utils_checkUserHasCapabilities
      }
    }
  }
`;

const getWorkspaceFromBootstrap = (
  bootstrapData: useUserHasCapabilitiesQuery$data['bootstrap'],
  cloudId: string | null,
) => {
  if (!bootstrapData || !bootstrapData.workspaces || !cloudId) {
    return null;
  }

  return bootstrapData.workspaces.filter(filterNull).find(workspace => workspace.cloudId === cloudId);
};

const getUserHasCapabilities = async (
  requirements: UserCapabilityType[],
  allCapabilitiesRequired: boolean,
  environment: Environment,
  cloudId: string,
  orgId: string,
) => {
  try {
    const data = await fetchQuery<useUserHasCapabilitiesQuery>(
      environment,
      userCapabilitiesQuery,
      {
        isValidWorkspace: true,
        context: {
          cloudId,
          orgId,
        },
      },
      {
        fetchPolicy: 'store-or-network',
      },
    ).toPromise();

    if (!data) {
      return false;
    }

    const workspace = getWorkspaceFromBootstrap(data.bootstrap, cloudId);

    return checkUserHasCapabilities(workspace, requirements, allCapabilitiesRequired);
  } catch (e) {
    return false;
  }
};

/**
 * This hook checks if the user has the capabilities that are passed in.
 * @param requirements - required capabilities
 * @param allCapabilitiesRequired - true if all capabilities in requirements are necessary, false if at least one is required
 * @returns true if user has capabilities, otherwise returns false (boolean)
 */
export const useUserHasCapabilities = (requirements: UserCapabilityType[], allCapabilitiesRequired: boolean): any => {
  const capabilityRouterEnabled = useFeatureGate('atlas-enable-capability-router');
  const environment = useRelayEnvironment();
  const goalWorkspace = useGoalWorkspace();
  const projectWorkspace = useProjectWorkspace();
  const workspaceCloudId = goalWorkspace ?? projectWorkspace;
  const [{ orgId }] = useOrgContextStore();

  const data = useClientQuery<useUserHasCapabilitiesQuery>(userCapabilitiesQuery, {
    isValidWorkspace: Boolean(capabilityRouterEnabled && workspaceCloudId && orgId),
    context: {
      cloudId: workspaceCloudId,
      orgId,
    },
  });

  const workspace = getWorkspaceFromBootstrap(data.bootstrap, workspaceCloudId);

  const [hasCapabilities, setHasCapabilities] = useState(
    checkUserHasCapabilities(workspace, requirements, allCapabilitiesRequired),
  );

  useEffect(() => {
    if (capabilityRouterEnabled && workspaceCloudId) {
      getUserHasCapabilities(requirements, allCapabilitiesRequired, environment, workspaceCloudId, orgId).then(
        setHasCapabilities,
      );
    }
  }, [capabilityRouterEnabled, environment, requirements, allCapabilitiesRequired, workspaceCloudId, orgId]);

  if (!capabilityRouterEnabled) {
    return true;
  } else if (!workspaceCloudId) {
    return false;
  } else {
    return hasCapabilities;
  }
};
