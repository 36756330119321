import { startOfDay, subDays } from 'date-fns';

import { formatIsoLocalDate, formatIsoLocalDateTime, tzDate, tzWorkspaceTimezoneDate } from '@townsquare/date-utils';
import { RelayResourceConfig, createRelayResource } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';
import { isNavRefreshEnabled } from '@townsquare/stat-sig/nav4';
import { tqlQueryFromInput } from '@townsquare/tql/query';
import { ComparatorOperator } from '@townsquare/tql/types';

import GoalUpdatesAsideViewQuery, {
  type GoalUpdatesAsideViewQuery as GoalUpdatesAsideViewQueryType,
} from '../GoalUpdatesView/__generated__/GoalUpdatesAsideViewQuery.graphql';

export const goalUpdatesAsideResource = createRelayResource<GoalUpdatesAsideViewQueryType>({
  getQuery: (_routerContext, resourceContext): RelayResourceConfig<GoalUpdatesAsideViewQueryType> => {
    const context = resourceContext as ResourceContext;
    const sidebarStartDate = formatIsoLocalDateTime(subDays(startOfDay(tzDate()), 7));
    const sidebarEndDate = formatIsoLocalDateTime(tzDate());

    const ownGoalsTqlQuery = tqlQueryFromInput({
      input: [
        { fieldName: 'owner', fieldValue: context.user.accountId, comparator: ComparatorOperator.EQUALS },
        { fieldName: 'archived', fieldValue: false, comparator: ComparatorOperator.EQUALS },
      ],
    });
    const newGoalsTqlQuery = tqlQueryFromInput({
      input: [
        { fieldName: 'creationDate', fieldValue: sidebarStartDate, comparator: ComparatorOperator.GTE },
        { fieldName: 'creationDate', fieldValue: sidebarEndDate, comparator: ComparatorOperator.LTE },
        { fieldName: 'archived', fieldValue: false, comparator: ComparatorOperator.EQUALS },
      ],
    });
    const completedGoalsTqlQuery = tqlQueryFromInput({
      input: [
        { fieldName: 'archived', fieldValue: false, comparator: ComparatorOperator.EQUALS },
        { fieldName: 'phase', fieldValue: 'done', comparator: ComparatorOperator.EQUALS },
        {
          fieldName: 'targetDate',
          fieldValue: formatIsoLocalDate(tzWorkspaceTimezoneDate(sidebarStartDate)),
          comparator: ComparatorOperator.GTE,
        },
        {
          fieldName: 'targetDate',
          fieldValue: formatIsoLocalDate(tzWorkspaceTimezoneDate(sidebarEndDate)),
          comparator: ComparatorOperator.LTE,
        },
      ],
    });

    return {
      query: GoalUpdatesAsideViewQuery,
      variables: {
        workspaceUUID: context.workspace?.UUID || '',
        ownGoalsTqlQuery: ownGoalsTqlQuery,
        newGoalsTqlQuery: newGoalsTqlQuery,
        completedGoalsTqlQuery: completedGoalsTqlQuery,
        isNavRefreshEnabled: isNavRefreshEnabled(resourceContext as ResourceContext),
      },
      cacheKey: 'goalUpdatesAside',
      options: {
        fetchPolicy: 'store-and-network',
      },
    };
  },
});
