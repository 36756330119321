import { PATH_PREFIX, SETTINGS_PAGE } from '@townsquare/config/routes';
import Loadable from '@townsquare/loadable';
import { createGlobalRoute, createSiteRoute } from '@townsquare/router/create-route';

import { noWorkspacePersonalSettingsPageResource } from './resources/noWorkspacePersonalSettings';
import { settingsPageResource } from './resources/settings';

export const settingsRoute = createSiteRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "settings-view" */ './components/SettingsView'),
    loading: () => null,
  }),
  exact: false,
  name: SETTINGS_PAGE,
  path: `${PATH_PREFIX.SETTINGS}/:pageName/:entity?`,
  resources: [settingsPageResource],
});

export const noWorkspacePersonalSettingsRoute = createGlobalRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "settings-view" */ './components/NoWorkspacePersonalSettingsView'),
    loading: () => null,
  }),
  exact: false,
  name: 'personal settings',
  path: `${PATH_PREFIX.SETTINGS}/personal`,
  resources: [noWorkspacePersonalSettingsPageResource],
});
