import Loadable from '@townsquare/loadable';

export const TeamHealthEmbed = Loadable({
  loader: () => import(/* webpackChunkName: "TeamHealthEmbed" */ './components/TeamHealthEmbed'),
  loading: () => null,
});

export { teamHealthResource } from './resource';

export { TEAM_HEALTH_ROUTE_NAME } from './constants';
