import { createRelayResource } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';
import { isGateEnabled } from '@townsquare/stat-sig/gate';

import HomeHeaderViewQuery, {
  HomeHeaderViewQuery as HomeHeaderViewQueryType,
} from '../components/__generated__/HomeHeaderViewQuery.graphql';

export const homeHeaderViewResource = createRelayResource<HomeHeaderViewQueryType>({
  getQuery: (_, resourceContext) => {
    const context = resourceContext as ResourceContext;
    const isHomeHeaderEnabled = isGateEnabled('townsquare_enable_home_header');

    return {
      query: HomeHeaderViewQuery,
      variables: {
        workspaceId: context.workspace?.globalId ?? undefined,
        isHomeHeaderEnabled,
      },
    };
  },
});
