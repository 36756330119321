/**
 * @generated SignedSource<<8efcc0b06fcf39433cbd25f871b6f97e>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type HomeHeaderViewQuery$variables = {
  isHomeHeaderEnabled: boolean;
  workspaceId?: string | null;
};
export type HomeHeaderViewQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"HomeHeaderContent_data">;
};
export type HomeHeaderViewQuery = {
  response: HomeHeaderViewQuery$data;
  variables: HomeHeaderViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isHomeHeaderEnabled"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workspaceId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "workspaceId",
    "variableName": "workspaceId"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "HomeHeaderViewQuery",
    "selections": [
      {
        "condition": "isHomeHeaderEnabled",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": (v2/*: any*/),
            "kind": "FragmentSpread",
            "name": "HomeHeaderContent_data"
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "HomeHeaderViewQuery",
    "selections": [
      {
        "condition": "isHomeHeaderEnabled",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": (v2/*: any*/),
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "aaid"
              },
              {
                "concreteType": "UserPreferences",
                "kind": "LinkedField",
                "name": "preferences",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "headerImage"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "randomiseImageEnabled"
                  },
                  (v3/*: any*/)
                ]
              },
              (v3/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "776aa3da9f9d6709d357812011a6542e",
    "id": null,
    "metadata": {},
    "name": "HomeHeaderViewQuery",
    "operationKind": "query",
    "text": "query HomeHeaderViewQuery(\n  $workspaceId: ID\n  $isHomeHeaderEnabled: Boolean!\n) {\n  ...HomeHeaderContent_data_26wvsp @include(if: $isHomeHeaderEnabled)\n}\n\nfragment HomeHeaderContent_data_26wvsp on Query {\n  currentUser(workspaceId: $workspaceId) {\n    aaid\n    preferences {\n      headerImage\n      randomiseImageEnabled\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "c3a0fb2ac0aa080ec48f385bad919d12";

export default node;
