import { ResourceStoreContext, RouterContext, RouterDataContext } from 'react-resource-router';

import { getCustomColumns, getStandardColumns } from '@townsquare/columns/hooks/use-shown-columns';
import { RelayResourceConfig, createRelayResource } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';

import GoalDirectoryQuery, {
  type DirectoryViewGoalQuery as GoalDirectoryTableQueryType,
} from '../components/__generated__/DirectoryViewGoalQuery.graphql';
import { DEFAULT_FIRST } from '../constants';
import { DEFAULT_GOAL_SORTS } from '../default-constants';
import { getDefaultGoalTqlQuery, getStandardColumnRelayVariables, resolveRawSort } from '../util';

export const getGoalDirectoryTableResourceQuery = (
  routerContext: RouterContext | RouterDataContext,
  resourceContext: ResourceStoreContext,
): RelayResourceConfig<GoalDirectoryTableQueryType> => {
  const context = resourceContext as ResourceContext;
  const directoryViewUuid = routerContext.query.viewUuid;
  const whenNotViewReturn = <T>(value: T) => (directoryViewUuid ? undefined : value);
  const defaultTqlQuery = getDefaultGoalTqlQuery();

  const isSavedView = !!routerContext.query.viewUuid;
  const selectedStandardColumns = getStandardColumns('goals', routerContext.query.columns);
  const selectedCustomColumns = getCustomColumns(routerContext.query.columns);

  return {
    query: GoalDirectoryQuery,
    variables: {
      tql: routerContext.query.tql ?? whenNotViewReturn(defaultTqlQuery),
      first: DEFAULT_FIRST,
      workspaceUuid: context.workspace?.UUID,
      containerId: context.collabContext?.contextId,
      sorts: resolveRawSort(routerContext.query.sort) ?? whenNotViewReturn(DEFAULT_GOAL_SORTS),
      directoryViewUuid: routerContext.query.viewUuid,
      ...getStandardColumnRelayVariables(selectedStandardColumns),
      includedCustomFieldUuids: selectedCustomColumns,
      // We have to manually refetch once the columns have loaded in from the view.
      // See DirectoryTable.tsx
      skipTableTql: isSavedView && routerContext.query.columns === undefined,
    },
    options: {
      fetchPolicy: 'store-and-network',
    },
  };
};

export const goalDirectoryTableResource = createRelayResource<GoalDirectoryTableQueryType>({
  getQuery: getGoalDirectoryTableResourceQuery,
});
