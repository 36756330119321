/**
 * @generated SignedSource<<e6785be56689e2fccfc38b514ab0917d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type NoWorkspacePersonalSettingsViewQuery$variables = Record<PropertyKey, never>;
export type NoWorkspacePersonalSettingsViewQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PersonalSettings">;
};
export type NoWorkspacePersonalSettingsViewQuery = {
  response: NoWorkspacePersonalSettingsViewQuery$data;
  variables: NoWorkspacePersonalSettingsViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v1 = [
  (v0/*: any*/),
  {
    "kind": "ScalarField",
    "name": "name"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "id"
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 200
  }
],
v4 = {
  "kind": "ScalarField",
  "name": "key"
},
v5 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "name": "NoWorkspacePersonalSettingsViewQuery",
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "PersonalSettings"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NoWorkspacePersonalSettingsViewQuery",
    "selections": [
      {
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "aaid"
          },
          {
            "concreteType": "TeamResponseBody",
            "kind": "LinkedField",
            "name": "slackTeams",
            "plural": true,
            "selections": (v1/*: any*/)
          },
          {
            "concreteType": "TenantResponseBody",
            "kind": "LinkedField",
            "name": "msteamsTenants",
            "plural": true,
            "selections": (v1/*: any*/)
          },
          {
            "concreteType": "UserPII",
            "kind": "LinkedField",
            "name": "pii",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "email"
              }
            ]
          },
          {
            "concreteType": "UserPreferences",
            "kind": "LinkedField",
            "name": "preferences",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "emailReminderEnabled"
              },
              {
                "kind": "ScalarField",
                "name": "slackReminderEnabled"
              },
              {
                "kind": "ScalarField",
                "name": "msteamsReminderEnabled"
              },
              {
                "kind": "ScalarField",
                "name": "emailSummaryEnabled"
              },
              {
                "kind": "ScalarField",
                "name": "slackSummaryEnabled"
              },
              {
                "kind": "ScalarField",
                "name": "msteamsSummaryEnabled"
              },
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "name": "headerImage"
              },
              {
                "kind": "ScalarField",
                "name": "randomiseImageEnabled"
              }
            ]
          },
          {
            "args": (v3/*: any*/),
            "concreteType": "OnboardingConnection",
            "kind": "LinkedField",
            "name": "onboarding",
            "plural": false,
            "selections": [
              {
                "concreteType": "OnboardingEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "Onboarding",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v2/*: any*/),
                      (v0/*: any*/)
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "name": "cursor"
                  }
                ]
              },
              {
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "endCursor"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "hasNextPage"
                  }
                ]
              },
              (v5/*: any*/)
            ],
            "storageKey": "onboarding(first:200)"
          },
          {
            "args": (v3/*: any*/),
            "handle": "connection",
            "key": "OnboardingSettingsQuery__onboarding",
            "kind": "LinkedHandle",
            "name": "onboarding"
          },
          {
            "alias": "optOut",
            "args": [
              {
                "kind": "Literal",
                "name": "filter",
                "value": {
                  "keys": [
                    "NAV_REFRESH_OPT_OUT"
                  ]
                }
              },
              {
                "kind": "Literal",
                "name": "first",
                "value": 1
              }
            ],
            "concreteType": "OnboardingConnection",
            "kind": "LinkedField",
            "name": "onboarding",
            "plural": false,
            "selections": [
              {
                "concreteType": "OnboardingEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "Onboarding",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v2/*: any*/)
                    ]
                  }
                ]
              },
              (v5/*: any*/)
            ],
            "storageKey": "onboarding(filter:{\"keys\":[\"NAV_REFRESH_OPT_OUT\"]},first:1)"
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "cacheID": "a5a9e16e4f3eb99cca559a9859e27830",
    "id": null,
    "metadata": {},
    "name": "NoWorkspacePersonalSettingsViewQuery",
    "operationKind": "query",
    "text": "query NoWorkspacePersonalSettingsViewQuery {\n  ...PersonalSettings\n}\n\nfragment ConnectionInfo_data on User {\n  aaid\n  slackTeams {\n    name\n  }\n  msteamsTenants {\n    name\n  }\n}\n\nfragment HeaderImageSettings_data on User {\n  preferences {\n    id\n    headerImage\n    randomiseImageEnabled\n  }\n}\n\nfragment IntegrationSettings_data on User {\n  aaid\n  slackTeams {\n    __typename\n  }\n  msteamsTenants {\n    __typename\n  }\n  pii {\n    email\n  }\n  ...NotificationSettingsForm_data\n}\n\nfragment NavRefreshOptOut on User {\n  optOut: onboarding(first: 1, filter: {keys: [NAV_REFRESH_OPT_OUT]}) {\n    edges {\n      node {\n        key\n        id\n      }\n    }\n  }\n}\n\nfragment NotificationSettingsForm_data on User {\n  preferences {\n    emailReminderEnabled\n    slackReminderEnabled\n    msteamsReminderEnabled\n    emailSummaryEnabled\n    slackSummaryEnabled\n    msteamsSummaryEnabled\n    id\n  }\n  ...ConnectionInfo_data\n  slackTeams {\n    name\n  }\n  msteamsTenants {\n    name\n  }\n}\n\nfragment OnboardingSettings_onboarding on User {\n  onboarding(first: 200) {\n    edges {\n      node {\n        key\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment PersonalSettings on Query {\n  currentUser {\n    ...IntegrationSettings_data\n    ...OnboardingSettings_onboarding\n    ...NavRefreshOptOut\n    ...HeaderImageSettings_data\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1c8bdbdc3c84e9afb18df7902fb2cde8";

export default node;
