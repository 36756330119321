import { Inline, xcss } from '@atlaskit/primitives';
import React from 'react';

import { LoadingSkeleton } from '@townsquare/loading-skeleton';

import { Actions } from './Actions';
import { Breadcrumbs } from './Breadcrumbs';
import { GriffinMainHeader } from './GriffinMainHeader';
import { Tabs } from './Tabs';
import { Title } from './Title';

type Props = {
  hideBreadcrumbs?: boolean;
};

export const SkeletonActions = () => (
  <>
    <LoadingSkeleton width="85px" height="32px" />
    <LoadingSkeleton width="85px" height="32px" />
  </>
);

export const SkeletonTabs = () => (
  <Inline space="space.150" xcss={xcss({ marginInline: 'space.300' })}>
    <LoadingSkeleton width="65px" height="26px" marginTopPx={5} marginBottomPx={5} />
    <LoadingSkeleton width="65px" height="26px" marginTopPx={5} marginBottomPx={5} />
    <LoadingSkeleton width="65px" height="26px" marginTopPx={5} marginBottomPx={5} />
  </Inline>
);

const SkeletonInternal = ({ hideBreadcrumbs }: Props) => {
  return (
    <GriffinMainHeader>
      {!hideBreadcrumbs && (
        <Breadcrumbs>
          <LoadingSkeleton width="70px" height="20px" marginTopPx={2} marginBottomPx={2} />
        </Breadcrumbs>
      )}
      <Title>
        <LoadingSkeleton width="250px" height="32px" />
      </Title>
      <Actions>
        <SkeletonActions />
      </Actions>
      <Tabs>
        <SkeletonTabs />
      </Tabs>
    </GriffinMainHeader>
  );
};

export const Skeleton = () => <SkeletonInternal />;
export const SkeletonWithoutBreadcrumbs = () => <SkeletonInternal hideBreadcrumbs />;
