import FeatureGates from '@atlaskit/feature-gate-js-client';

import type { ResourceContext } from '@townsquare/router';
import { useWorkspaceStore } from '@townsquare/workspace-store';

import { isGateEnabled } from '../Gate';
import { COHORT_PARAMETER, Cohort } from '../cohorts';
import { NAVX_UNIVERSAL_CREATE_EXPERIMENT } from '../experiment-keys';

import { shouldEnableNavRefresh } from './should-enable-nav-refresh';

export const useIsNavRefreshEnabled = () => {
  const [workspace] = useWorkspaceStore();
  return shouldEnableNavRefresh({
    isNav4Admin: Boolean(workspace?.capabilities?.orgAdmin),
    isSiteEnrolledToNav4: Boolean(workspace?.isSiteEnrolledToNav4),
  });
};

type ExpectedResourceContext = Pick<ResourceContext, 'workspace'>;
export const isNavRefreshEnabled = (context: ExpectedResourceContext) => {
  return shouldEnableNavRefresh({
    isNav4Admin: Boolean(context.workspace?.capabilities?.orgAdmin),
    isSiteEnrolledToNav4: Boolean(context.workspace?.isSiteEnrolledToNav4),
  });
};

export const useUniversalCreateEnabled = () => {
  const isHomeGateEnabled = isGateEnabled('atlassian_home_universal_create');
  const isNavRefreshEnabled = useIsNavRefreshEnabled();

  const cohort = FeatureGates.getExperimentValue(
    NAVX_UNIVERSAL_CREATE_EXPERIMENT,
    COHORT_PARAMETER,
    'control',
  ) as Cohort;

  return isNavRefreshEnabled && isHomeGateEnabled && cohort === 'variation';
};

// Your Apps Experiment
export const useIsYourAppsExperimentActive = () => {
  /**
   * TODO in TC-11197:
   *   -- Control: 'Your Apps' widget in the Home tab with `Try` cards,  LHS apps section & view all apps page have no crossflow
   *   -- Var 1: NO 'Your Apps' widget, NO crossflow anywhere
   *   -- Var 2: `Try` as a switcher item the “Your apps” in the side Nav, `Try` cards on “Apps” page
   */
  const enableYourAppsExperiment = isGateEnabled('atlassian_home_your_apps_experiment_gate');
  const isNavRefreshEnabled = useIsNavRefreshEnabled();

  return (
    isNavRefreshEnabled && // Only for nav4 users
    enableYourAppsExperiment
  );
};
