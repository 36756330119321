import { PEOPLE_DIRECTORY_PATH, STAFF_DIRECTORY_PAGE } from '@townsquare/config/routes';
import { createGlobalRoute } from '@townsquare/router/create-route';
import { titanEapCrossFlowQueryResource } from '@townsquare/titan-eap-cross-flow/resource';

export const embeddableDirectoryRoute = createGlobalRoute({
  exact: true,
  name: STAFF_DIRECTORY_PAGE,
  path: `${PEOPLE_DIRECTORY_PATH}/:search(search)?/:teams(teams)?`,
  resources: [titanEapCrossFlowQueryResource],
  subproduct: 'townsquare-people-team-directory',
});
