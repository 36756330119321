import { OptionsType } from '@atlaskit/select';
import { ReactNode } from 'react';
import type { Environment } from 'react-relay';

//#region Change functions
export type onValueFn = (
  entityIndex: number,
  type: SupportedFilters,
  value: string | number,
  operators: Operators[],
  comparators: ComparatorOperator[],
) => void;
export type onOperatorChangeFn = (entityIndex: number, type: SupportedFilters, operator: Operators) => void;
export type onComparatorChangeFn = (
  entityIndex: number,
  type: SupportedFilters,
  comparator: ComparatorOperator,
  operator?: Operators,
) => void;
export type onRootOperatorChangeFn = (operator: Operators) => void;
export type onRemoveFn = (entityIndex: number, type: SupportedFilters, modelIndex: number) => void;
//#endregion

//#region FilterDoc
export enum SupportedFiltersTypes {
  LABEL = 'label',
  GOAL_SCORE = 'score',
  GOAL_STATUS = 'status_phase',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  PROJECT_STATUS = 'status_phase',
  OWNER = 'owner',
  CONTRIBUTOR = 'contributor',
  WATCHER = 'watcher',
  TEAM = 'team',
  PROJECT = 'project',
  GOAL = 'goal',
  JOB_TITLE = 'job_title',
  MANAGER = 'manager',
  DEPARTMENT = 'department',
  LOCATION = 'location',
  TEAM_MEMBER = 'team_member',
  REPORTING_LINE = 'reporting_line',
  REPORTING_LINE_EMPTY = 'reporting_line_empty_tql_state',
  CREATION_DATE = 'creationDate',
  GIVER = 'giver',
  RECIPIENT = 'recipient',
  RECIPIENT_TEAM = 'recipientTeamMember',
  METRIC = 'metric',
  FOCUS_AREA = 'focusAreaAris',
  VERIFIED_TEAM_FILTER = 'isVerifiedTeamFilter',
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type SupportedFilters = SupportedFiltersTypes | (string & {});

export enum Operators {
  OR = 'or',
  AND = 'and',
}

export enum ComparatorOperator {
  EQUALS = '=',
  NOT_EQUALS = '!=',
  LIKE = 'LIKE',
  GT = '>',
  GTE = '>=',
  LT = '<',
  LTE = '<=',
  // The raw values for these operators needs to match the canonical value returned by the TQL explainer
  NOT_IN = 'IS NOT IN',
  IN = 'IS IN',
  BETWEEN = '<>',
}

export type MultiValueComparator = ComparatorOperator.IN | ComparatorOperator.NOT_IN;
export type SingleValueOperator = Exclude<ComparatorOperator, MultiValueComparator>;

export type Content = string | number;
export interface Operator {
  operator: Operators;
}

export interface Entity extends Operator {
  type: SupportedFilters;
  model: Content[];
  comparator: ComparatorOperator;
}

export interface FilterDoc extends Operator {
  model: Entity[];
}
//#endregion

//#region Resolvers
export enum OptionTypes {
  SELECT = 'select',
  EMPTY = 'empty',
  TOGGLE = 'toggle',
  NUMBER = 'number',
}

export interface ResolveLabelsConfig {
  values: Content[];
  relayEnvironment: Environment;
  workspaceId?: string;
  workspaceUUID?: string;
}

export type FilterOperator = {
  operator: Operators;
  operatorText?: string;
};

export type SingleValueFilterComparator = {
  comparatorText?: string;
  comparatorOption: SingleValueOperator;
  allowedOperators: FilterOperator[];
};

export type MultiValueFilterComparator = {
  comparatorText?: string;
  comparatorOption: MultiValueComparator;
  allowedOperators: [];
  multiValueSeparator: string;
};

export type FilterComparators = SingleValueFilterComparator | MultiValueFilterComparator;

export interface BaseResolver<Option extends ReadonlyArray<{ label: string; value: string | number | boolean }>> {
  type: SupportedFilters;
  title: string;
  placeholder?: string;
  icon?: JSX.Element;
  resolveLabels: (config: ResolveLabelsConfig) => Promise<Option>;
  filterComparators: FilterComparators[];
  emptyStateComponent?: EmptyStateResolver;
}

export type SelectResolverOption = {
  /**
   * The string that will show up inside the
   * filter lozenges
   */
  label: string;
  /**
   * This is value that will be sent to Graph
   * for resolution of results and labels.
   */
  value: Content;
  /**
   * An optional icon to provide the select
   * that will prefix the label text
   */
  icon?: JSX.Element;
  /**
   * If a component is provided it will be used
   * to render the option instead of label / icon combination
   */
  component?: JSX.Element;
};
export type SelectResolverOptions = OptionsType<SelectResolverOption>;

export interface ResolveOptionsConfig {
  relayEnvironment: Environment;
  query?: string;
  workspaceId?: string;
  workspaceUUID?: string;
}
export interface SelectResolver extends BaseResolver<SelectResolverOptions> {
  optionType: OptionTypes.SELECT;
  resolveOptions: (config: ResolveOptionsConfig) => Promise<SelectResolverOptions>;
}

export interface EmptyStateResolver extends BaseResolver<ReadonlyArray<Record<'label' | 'value', never>>> {
  optionType: OptionTypes.EMPTY;
  render: () => JSX.Element;
}

export interface ToggleStateResolver extends BaseResolver<ReadonlyArray<Record<'label' | 'value', string>>> {
  optionType: OptionTypes.TOGGLE;
  render: () => SelectResolverOption;
}

export interface NumberResolver extends BaseResolver<ReadonlyArray<{ label: string; value: number }>> {
  optionType: OptionTypes.NUMBER;
  // filterComparators: NumberComparatorOperator[];
}
// Extend this with more resolver types
export type Resolver = SelectResolver | EmptyStateResolver | ToggleStateResolver | NumberResolver;
export type Resolvers = Resolver[];
//#endregion

export type OnChangeOption<T extends Resolver> = T extends SelectResolver
  ? SelectResolverOption
  : T extends NumberResolver
  ? number[]
  : never;

// Custom field resolver types
export type Unpacked<T> = T extends (infer U)[] ? U : T;

export interface ResolverPopupProps<ResolverType extends Resolver = Resolver> {
  resolver: ResolverType;
  isOpen: boolean | undefined;
  target: (args: { ref: any; isOpen?: boolean }) => ReactNode;
  onChange: (value: OnChangeOption<ResolverType>) => void;
  onClose: () => void;
  onComparatorChange?: (option: { label: string; value: ComparatorOperator }) => void;
}

type FieldName = string;
export type FieldValue = string | number | boolean;

export interface Input {
  fieldName: FieldName;
  fieldValue?: FieldValue;
  comparator: ComparatorOperator;
}

export interface Join {
  join: Operators;
}

export type ExplainedInput = Map<Input['fieldName'], Input['fieldValue']>;

interface SystemFilter {
  applyTql: () => string[];
}

export interface InputOptions {
  // TODO: change to only support array
  doc?: FilterDoc | FilterDoc[];
  input?: (Input | Join)[];
  filter?: SystemFilter | null;
}
