import Loadable from '@townsquare/loadable';

import { agentProfileRoute } from './route-definitions/agent-profile';
import { embeddableDirectoryRoute } from './route-definitions/embeddable-directory';
import { kudosProfileRoute } from './route-definitions/kudos-profile';
import { profileRoute } from './route-definitions/profile';
import { teamProfileRoute } from './route-definitions/team-profile';

const DirectoryViewLoadable = Loadable({
  loader: () => import(/* webpackChunkName: "DirectoryPage" */ './components/DirectoryView'),
  loading: () => null,
});

profileRoute.component = DirectoryViewLoadable;
export const profileRouterRoute = profileRoute;

teamProfileRoute.component = DirectoryViewLoadable;
export const teamProfileRouterRoute = teamProfileRoute;

agentProfileRoute.component = DirectoryViewLoadable;
export const agentProfileRouterRoute = agentProfileRoute;

kudosProfileRoute.component = DirectoryViewLoadable;
export const kudosProfileRouterRoute = kudosProfileRoute;

embeddableDirectoryRoute.component = DirectoryViewLoadable;
export const embeddableDirectoryRouterRoute = embeddableDirectoryRoute;
