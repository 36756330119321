import { Query, Route, matchRoute as RRRmatchRoute } from 'react-resource-router';

import { expandGlobalRoute } from '../home/create-route';
import { HomepageRoute, HomepageRouteType } from '../home/types';

export const matchRoute = (
  routes: HomepageRouteType[],
  pathname: string,
  queryParams?: Query | undefined,
  basePath?: string,
) => {
  const remappedRoutes = routes.flatMap<HomepageRouteType>(route => {
    if (route.routeType === 'global') {
      return expandGlobalRoute(route);
    }
    return route;
  });

  return RRRmatchRoute(remappedRoutes, pathname, queryParams, basePath);
};

export const isEmbedRoute = (route: Route | undefined): route is HomepageRoute => {
  return (route && 'embed' in route && typeof route.embed === 'boolean' && route.embed) ?? false;
};
