import { getLogInUrl } from '@townsquare/auth-utils';
import { getConfig } from '@townsquare/config';
import { identityClient } from '@townsquare/identity-client';
import Loadable from '@townsquare/loadable';
import { createGlobalRoute } from '@townsquare/router/create-route';

const POPUP_LOGIN_POLL_TIMER_INTERVAL_IN_MILLIS = 200;

export const popupAuthRoute = createGlobalRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "popup-auth" */ './PopupAuth'),
    loading: () => null,
  }),
  exact: true,
  embed: true, // For chromeless
  name: 'pop-up-auth',
  path: '/popup-auth',
});

export const loginViaPopup = (options?: { onAuthenticated?: () => void; onUnauthenticated?: () => void }) => {
  const pop = window.open(
    getLogInUrl(`${getConfig().fullUrl}/popup-auth`),
    '_blank',
    'toolbar=0,location=0,menubar=0,width=800,height=600',
  );

  const pollTimer = window.setInterval(() => {
    if (!pop || pop.closed !== false) {
      // !== is required for compatibility with Opera
      window.clearInterval(pollTimer);
      identityClient.getUserIsAuthenticated({ forceRefresh: true }).then(
        ({ isAuthenticated }) => {
          if (isAuthenticated) {
            options?.onAuthenticated?.();
          } else {
            options?.onUnauthenticated?.();
          }
        },
        () => {
          options?.onUnauthenticated?.();
        },
      );
    }
  }, POPUP_LOGIN_POLL_TIMER_INTERVAL_IN_MILLIS);
};
