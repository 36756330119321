/**
 * @generated SignedSource<<972cea5b68321bb5ac3ad17885e04bf4>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type OrganizationContextInput = {
  clientMutationId?: string | null;
  cloudId?: string | null;
  orgId: string;
};
export type useUserHasCapabilitiesQuery$variables = {
  context: OrganizationContextInput;
  isValidWorkspace: boolean;
};
export type useUserHasCapabilitiesQuery$data = {
  readonly bootstrap?: {
    readonly workspaces: ReadonlyArray<{
      readonly cloudId: string;
      readonly " $fragmentSpreads": FragmentRefs<"utils_checkUserHasCapabilities">;
    } | null> | null;
  } | null;
};
export type useUserHasCapabilitiesQuery = {
  response: useUserHasCapabilitiesQuery$data;
  variables: useUserHasCapabilitiesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "context"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isValidWorkspace"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "organizationContext",
    "variableName": "context"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "cloudId"
},
v3 = {
  "kind": "ScalarField",
  "name": "capability"
},
v4 = {
  "kind": "ScalarField",
  "name": "capabilityContainer"
},
v5 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "useUserHasCapabilitiesQuery",
    "selections": [
      {
        "condition": "isValidWorkspace",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "AtlassianHomeBootstrap",
            "kind": "LinkedField",
            "name": "bootstrap",
            "plural": false,
            "selections": [
              {
                "concreteType": "WorkspaceSummary",
                "kind": "LinkedField",
                "name": "workspaces",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "InlineDataFragmentSpread",
                    "name": "utils_checkUserHasCapabilities",
                    "selections": [
                      {
                        "concreteType": "UserCapabilities",
                        "kind": "LinkedField",
                        "name": "userCapabilities",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "Capability",
                            "kind": "LinkedField",
                            "name": "capabilities",
                            "plural": true,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/)
                            ]
                          }
                        ]
                      }
                    ],
                    "argumentDefinitions": []
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUserHasCapabilitiesQuery",
    "selections": [
      {
        "condition": "isValidWorkspace",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "AtlassianHomeBootstrap",
            "kind": "LinkedField",
            "name": "bootstrap",
            "plural": false,
            "selections": [
              {
                "concreteType": "WorkspaceSummary",
                "kind": "LinkedField",
                "name": "workspaces",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "concreteType": "UserCapabilities",
                    "kind": "LinkedField",
                    "name": "userCapabilities",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "Capability",
                        "kind": "LinkedField",
                        "name": "capabilities",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v5/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "1f82c8abf1384b3de6c249178502cfc1",
    "id": null,
    "metadata": {},
    "name": "useUserHasCapabilitiesQuery",
    "operationKind": "query",
    "text": "query useUserHasCapabilitiesQuery(\n  $context: OrganizationContextInput!\n  $isValidWorkspace: Boolean!\n) {\n  bootstrap(organizationContext: $context) @include(if: $isValidWorkspace) {\n    workspaces {\n      cloudId\n      ...utils_checkUserHasCapabilities\n      id\n    }\n  }\n}\n\nfragment utils_checkUserHasCapabilities on WorkspaceSummary {\n  userCapabilities {\n    capabilities {\n      capability\n      capabilityContainer\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cad6604c351c1cefd4c284e96bca8665";

export default node;
