import { Box, xcss } from '@atlaskit/primitives';
import React from 'react';

const styles = xcss({
  marginBlockStart: 'space.100',
  marginInline: 'space.negative.300',
  /* eslint-disable @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/design-system/no-nested-styles */
  // @ts-expect-error - Remove bottom border on platform tabs via nested style as there's no other way to do this currently
  'nav::before': {
    display: 'none',
  },
  /* eslint-enable @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/design-system/no-nested-styles */
});

const wrapperStyles = xcss({
  gridArea: 'tabs',
  overflowX: 'hidden',
});

export const Tabs = ({ children }: React.PropsWithChildren<unknown>) => {
  return (
    <Box xcss={wrapperStyles}>
      <Box xcss={styles}>{children}</Box>
    </Box>
  );
};
