import Loadable from '@townsquare/loadable';

export const Milestone = Loadable({
  loader: () => import(/* webpackChunkName: 'milestone' */ './Milestone'),
  loading: () => null,
});

export const MilestoneForm = Loadable({
  loader: () => import(/* webpackChunkName: 'milestonesForm' */ './MilestonesForm'),
  loading: () => null,
});

export const MilestoneCard = Loadable({
  loader: () => import(/* webpackChunkName: 'milestoneCard' */ './MilestoneCard'),
  loading: () => null,
});
