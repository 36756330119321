import { PROJECT_DIRECTORY_PAGE, PROJECT_DIRECTORY_PATH } from '@townsquare/config/routes';
import { directoryActiveViewResource, directoryFavoriteViewsResource } from '@townsquare/directory-header/resources';
import Loadable from '@townsquare/loadable';
import { onboardingResource } from '@townsquare/onboarding/resource';
import { createGlobalRoute } from '@townsquare/router/create-route';
import { titanEapCrossFlowQueryResource } from '@townsquare/titan-eap-cross-flow/resource';
import { tqlExplainerResource } from '@townsquare/tql/resource';

import { ProjectsEmptyOrNonProvisionedState } from './components/EmptyOrNonProvisionedState';
import { projectDirectoryResource } from './resources/project-directory';
import { projectDirectoryViewResource } from './resources/project-directory-view';

export const projectDirectoryRoute = createGlobalRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "project-view" */ './components/ProjectDirectory'),
    loading: () => null,
  }),
  exact: false,
  name: PROJECT_DIRECTORY_PAGE,
  path: PROJECT_DIRECTORY_PATH,
  capabilities: ['projects_app:access'],
  resources: [
    onboardingResource,
    projectDirectoryResource,
    projectDirectoryViewResource,
    directoryFavoriteViewsResource,
    tqlExplainerResource,
    directoryActiveViewResource,
  ],
  subproduct: 'townsquare-projects',
  enableRestrictedFallback: true,
});

export const projectNoWorkspaceDirectoryRoute = createGlobalRoute({
  component: ProjectsEmptyOrNonProvisionedState,
  exact: false,
  name: PROJECT_DIRECTORY_PAGE,
  path: PROJECT_DIRECTORY_PATH,
  resources: [titanEapCrossFlowQueryResource],
  subproduct: 'townsquare-projects',
});
