import { PEOPLE_KUDOS_PATH_PATTERN, KUDOS_PAGE } from '@townsquare/config/routes';
import { createGlobalRoute } from '@townsquare/router/create-route';

export const kudosProfileRoute = createGlobalRoute({
  exact: true,
  name: KUDOS_PAGE,
  path: PEOPLE_KUDOS_PATH_PATTERN,
  subproduct: 'townsquare-kudos',
  layout: 'expanded',
});
