import Loadable from '@townsquare/loadable';

export type {
  BaseIconProps,
  IconPreviewProps,
  IconProps,
  IconURIs,
  PickerProps,
  SSRIcon,
  Size,
  ThemeURIs,
} from './types';

export { DEFAULT_EMOJI, DEFAULT_COLOR } from './components/utils';

export const ProjectIcon = Loadable({
  loader: () => import(/* webpackChunkName: "project-icon" */ './components/ProjectIcon'),
  loading: () => null,
});

export const IconPreviewer = Loadable({
  loader: () => import(/* webpackChunkName: "icon-previewer" */ './components/PickerModal/IconPreviewer'),
  loading: () => null,
});

export const EmojiPreviewer = Loadable({
  loader: () => import(/* webpackChunkName: "emoji-previewer" */ './components/PickerModal/EmojiPreviewer'),
  loading: () => null,
});

export const BaseProjectIcon = Loadable({
  loader: () => import(/* webpackChunkName: "base-project-icon" */ './components/BaseProjectIcon'),
  loading: () => null,
});

export const BaseProjectIconPicker = Loadable({
  loader: () => import(/* webpackChunkName: "base-project-icon-picker" */ './components/BaseProjectIconPicker'),
  loading: () => null,
});

export const ProjectIconPicker = Loadable({
  loader: () => import(/* webpackChunkName: "project-icon-picker" */ './components/ProjectIconPicker'),
  loading: () => null,
});

export { sizeMap } from './components/styles';
