import Loadable from '@townsquare/loadable';
import { createGlobalRoute } from '@townsquare/router/create-route';

export const agentStudioRoute = createGlobalRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "agent-studio" */ './components/AgentStudioView'),
    loading: () => null,
  }),
  exact: true,
  name: 'agent-studio',
  path: '/agent-studio',
  layout: 'home',
  nav4Layout: 'expanded',
});
