import Loadable from '@townsquare/loadable';
import { createGlobalRoute } from '@townsquare/router/create-route';
import { rovoEntitlementResource } from '@townsquare/rovo/entitlement';

export const rovoRoute = createGlobalRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "chat-view" */ './components/RovoView'),
    loading: () => null,
  }),
  exact: true,
  name: 'rovo',
  path: '/rovo',
  layout: 'home',
  nav4Layout: 'expanded',
  resources: [rovoEntitlementResource],
});
