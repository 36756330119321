/**
 * @generated SignedSource<<47ef395f79433b6cd0456cc6951c0ca0>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type AccessControlCapability = "ACCESS" | "ADMINISTER" | "CREATE";
export type CapabilityContainer = "FOCUS_AREAS_APP" | "GOALS_APP" | "PROJECTS_APP";
import type { FragmentRefs } from "relay-runtime";
export type utils_checkUserHasCapabilities$data = {
  readonly userCapabilities: {
    readonly capabilities: ReadonlyArray<{
      readonly capability: AccessControlCapability | null;
      readonly capabilityContainer: CapabilityContainer | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "utils_checkUserHasCapabilities";
};
export type utils_checkUserHasCapabilities$key = {
  readonly " $data"?: utils_checkUserHasCapabilities$data;
  readonly " $fragmentSpreads": FragmentRefs<"utils_checkUserHasCapabilities">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "utils_checkUserHasCapabilities"
};

(node as any).hash = "6585ad41b6c87acabf6385eca5f7198c";

export default node;
