import { getAggEndpoint } from '@atlassian/relay-endpoint';
import { Environment, Network, RecordSource, Store } from 'relay-runtime';
import type { CacheConfig, RequestParameters, Variables } from 'relay-runtime';

import { getConfig, getReleaseVersion } from '@townsquare/config';
import { PRODUCT_DISPLAY_NAME } from '@townsquare/config/constants';

import { fetchGraphQLFromUrl, logMissingRequiredField } from './RelayEnvironment';

// These types are prefixed with the AGG prefix to avoid conflicts with other types
const AGG_PREFIX_TYPES = ['SearchResultAtlasGoal', 'SearchResultAtlasProject'];

/**
 * NOTE: This is pulled from @atlassian/relay-endpoint
 *
 * We've had to hack in query -> request.text to the response body, as the upstream one doesn't
 * appear to use it, and outgoing AGG requests using @a/relay-environment-config (or -provider)
 * fail
 */
export const getRequestBody = (request: RequestParameters, variables: Variables) => {
  const requestBody: {
    operationName: string;
    variables: Variables;
    query?: string | null | undefined;
  } = {
    operationName: request.name,
    variables,
    query: request.text,
  };

  return JSON.stringify(requestBody);
};

/**
 * NOTE: Pulled from @atlassian/relay-network
 *
 * This is made to use our custom getRequestBody to fix the null query issue
 */
function fetchQuery(request: RequestParameters, variables: Variables, cacheConfig: CacheConfig) {
  const endpoint = getAggEndpoint(request, cacheConfig);
  const body = getRequestBody(request, variables);

  return fetch(endpoint, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      // Add authentication and other headers here
      // Required by some services for rate-limiting purposes
      'atl-client-name': 'townsquare-frontend',
      'atl-client-version': getReleaseVersion(),
      'content-type': 'application/json',
    },
    body,
  }).then(response => {
    return response.json();
  });
}

// Create a network layer from the fetch function
export const network = Network.create(fetchQuery);

export const AggRelayEnvironment = new Environment({
  configName: `Atlassian Graphql Gateway`,
  network: network,
  store: new Store(new RecordSource()),
  relayFieldLogger: logMissingRequiredField,
  getDataID: (fieldValue, typeName) => {
    if (AGG_PREFIX_TYPES.includes(typeName)) {
      return `${typeName}:${fieldValue.id}`;
    }

    return fieldValue.id;
  },
});

const aggExperimentalConfig = getConfig();
const EXPERIMENTAL_JIRA_API_HEADER = { 'X-ExperimentalApi': 'JiraIssueSearch' };

export const AggExperimentalJiraRelayEnvironment = new Environment({
  configName: `${PRODUCT_DISPLAY_NAME} AGG GraphQL Experimental`,
  network: Network.create(
    fetchGraphQLFromUrl({
      defaultUrl: aggExperimentalConfig.aggGraphQLUrl,
      multiRegionUrl: undefined,
      customHeaders: EXPERIMENTAL_JIRA_API_HEADER,
    }),
  ),
  store: new Store(new RecordSource()),
  relayFieldLogger: logMissingRequiredField,
});
