import { createRelayResource } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';
import { isNavRefreshEnabled } from '@townsquare/stat-sig/nav4';

import GoalViewAsideQuery, {
  type GoalViewAsideQuery as GoalViewAsideQueryType,
} from '../components/__generated__/GoalViewAsideQuery.graphql';

export const goalViewAsideResource = createRelayResource<GoalViewAsideQueryType>({
  getQuery: (routerContext, resourceContext) => {
    return {
      query: GoalViewAsideQuery,
      variables: {
        key: routerContext.match.params.id ?? '',
        teamTql: routerContext.match.params.id && `goal = ${routerContext.match.params.id}`,
        trackViewEvent: 'DIRECT',
        isNavRefreshEnabled: isNavRefreshEnabled(resourceContext as ResourceContext),
        containerId: (resourceContext as ResourceContext).collabContext?.contextId ?? '',
      },
    };
  },
});
