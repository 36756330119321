import {
  addDays,
  addMonths,
  addWeeks,
  differenceInCalendarDays,
  differenceInMinutes,
  isAfter,
  isBefore,
  isPast,
  isSameMonth,
  subDays,
  subMonths,
  subWeeks,
} from 'date-fns';
import { getTimezoneOffset, utcToZonedTime } from 'date-fns-tz';

import { MINUS_MONTHS_AT_START_OF_VIEW, MONTHS_IN_TIMELINE_VIEW } from './constants';
import { isSameWeek } from './mixins';
import { utcToUserTimezone, utcToWorkspaceTimezone } from './timezone-datetime';
import timezoneHolder from './timezoneHolder';

export const sevenDaysAgo = () => subWeeks(tzDate(), 1);

export const isThisWeekInWorkspaceTZ = (date: Date) => isSameWeek(date, tzDate());
export const isThisWeekInUserTZ = (date: Date) => isSameWeek(date, tzUserTimezoneDate());
export const isLastWeekInWorkspaceTZ = (date: Date) => isSameWeek(date, addWeeks(tzDate(), -1));
export const isLastWeekInUserTZ = (date: Date) => isSameWeek(date, addWeeks(tzUserTimezoneDate(), -1));
export const isLastMonth = (date: Date) => isSameMonth(date, addMonths(tzDate(), -1));

export const plusDays = (date: Date, amount: number) => addDays(date, amount);
export const plusMonths = (date: Date, amount: number) => addMonths(date, amount);
export const minusDays = (date: Date, amount: number) => subDays(date, amount);
export const minusMonths = (date: Date, amount: number) => subMonths(date, amount);
export const isBeforeStart = (date: Date, startDate: Date) => isBefore(date, startDate);
export const isAfterEnd = (date: Date, endDate: Date) => isAfter(date, endDate);

/**
 * @deprecated Use tzWorkspaceTimezoneDate instead for better clarity
 */
export const tzDate = (dateString?: string | null) => tzWorkspaceTimezoneDate(dateString);

/**
 * Create a date object that has been "time-shifted" to WORKSPACE timezone.
 * We'll parse the given date string if available, otherwise it will be based on the user's current local computer time
 */
export const tzWorkspaceTimezoneDate = (dateString?: string | null) => {
  return dateString
    ? utcToWorkspaceTimezone(dateString)
    : utcToZonedTime(Date.now(), timezoneHolder.getWorkspaceTimezone());
};

/**
 * Used for offsetting UTC dates to the workspace timezone.
 * For example. Lets say we wanted start of day in America/Los Angeles [-08:00]
 * We can pass in 00:00PM UTC here - and this function will offset to 08:00PM UTC
 */
const offsetUtcDateToWorkspaceDate = (date: Date) => {
  const timezoneOffset = getTimezoneOffset(timezoneHolder.getWorkspaceTimezone());
  return new Date(date.valueOf() - timezoneOffset);
};

/**
 * Used for offsetting workspace timezoned dates back to UTC.
 */
const offsetWorkspaceDateToUtcDate = (date: Date) => {
  const timezoneOffset = getTimezoneOffset(timezoneHolder.getWorkspaceTimezone());
  return new Date(date.valueOf() + timezoneOffset);
};

/**
 * Create a date object that has been "time-shifted" to USER timezone.
 * We'll parse the given date string if available, otherwise it will be based on the user's current local computer time
 */
export const tzUserTimezoneDate = (dateString?: string) =>
  dateString ? utcToUserTimezone(dateString) : utcToZonedTime(Date.now(), timezoneHolder.getUserTimezone());

/**
 * Used for offsetting dates created with date-fns (start of day etc) that actually need to be UTC time.
 * For example. a startOfDay in australia would generate 00:00PM AEST, but that's 1:00PM UTC the previous day
 * This function will offset by the timezone difference, +11:00 in this case. Bringing us back to 00:00PM UTC.
 */
const offsetUserDateToUtcDate = (date: Date) => {
  const timezoneOffset = getTimezoneOffset(timezoneHolder.getUserTimezone(), date);
  return new Date(date.valueOf() + timezoneOffset);
};

/**
 * Used for offsetting utc dates to the user's timezone.
 */
export const offsetUtcDateToUserDate = (date: Date) => {
  const timezoneOffset = getTimezoneOffset(timezoneHolder.getUserTimezone(), date);
  return new Date(date.valueOf() - timezoneOffset);
};

/**
 * Used for offsetting dates created with date-fns (start of day etc) that actually need to be workspace zone times.
 * Works by offsetting the users timezone off first, getting the date-fns time to be UTC. Then we apply the timezone
 * of the workspace.
 */
export const offsetUserDateToWorkspaceDate = (date: Date) =>
  offsetUtcDateToWorkspaceDate(offsetUserDateToUtcDate(date));

/**
 * Reverses the effects of offsetUserDateToWorkspaceDate. This allows us to use date-fns against times that need
 * to match what we're using for the workspace. Because date-fns relies on the user's timezone. Ugh what a headache!
 */
export const offsetWorkspaceDateToUserDate = (date: Date) =>
  offsetUtcDateToUserDate(offsetWorkspaceDateToUtcDate(date));

export const isDateInThePast = (dateString?: string | null) =>
  dateString ? isPast(new Date(`${dateString.split('T')[0]}T23:59:59`)) : false;

export const getDifferenceInCalendarDays = (newDate: string, oldDate: string) =>
  differenceInCalendarDays(new Date(newDate), new Date(oldDate));

/**
 *
 * @param newTime
 * @param oldTime
 * @returns boolean that is true if newTime is more than 5 minutes after oldTime
 */
export const differenceIsGreaterThanFiveMinutes = (newTime: string | number, oldTime: string | number) =>
  differenceInMinutes(Number(newTime), Number(oldTime)) > 5;

export const startDateMax = plusMonths(
  minusMonths(tzWorkspaceTimezoneDate(), MINUS_MONTHS_AT_START_OF_VIEW),
  MONTHS_IN_TIMELINE_VIEW,
)
  .toISOString()
  .split('T')[0];
export const targetDateMin = minusMonths(tzWorkspaceTimezoneDate(), MINUS_MONTHS_AT_START_OF_VIEW)
  .toISOString()
  .split('T')[0];
