import { Behaviours, createProvisioningPageExtensions, Prompts, TargetTabs } from '@atlassiansox/cross-flow-extensions';
import { createStartExtensions } from '@atlassiansox/cross-flow-extensions/start';
import { CrossFlowConsumer, Journeys } from '@atlassiansox/cross-flow-support';
import { default as AtlasCrossFlowProvider } from '@atlassiansox/cross-flow-support/start';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useAtlasKitAnalytics } from '@townsquare/analytics';
import { stripParams } from '@townsquare/routing/utils';

import { getContainersPlugin } from '../utils/get-containers';
import { getUsersPlugin } from '../utils/get-users';
import { getCrossFlowParams } from '../utils/params';

export const WAC_CROSSFLOW_PARAMS = [
  'startTrialCloudId',
  'startTrialProduct',
  'startTrialInstance',
  'edition',
  'migrationSourceUuid',
  'billingMode',
  'sourceContext',
  'targetTab',
  'sourceComponent',
  'templateId',
];

function stripCrossFlowParams() {
  return stripParams(...WAC_CROSSFLOW_PARAMS);
}

/**
 * "WAC via Start" specific implementation of cross-flow.
 *
 * Will bring up the Cross Flow UI overlay when a startTrialProduct is present in the query string.
 *
 * Should be mounted as a sibling of Cross Flow Provider and won't impact other touch points.
 */
const CrossFlowWAC = () => {
  const { locale } = useIntl();
  const crossflowAnalyticsClient = useAtlasKitAnalytics();

  const {
    startTrialCloudId,
    startTrialProduct,
    edition,
    migrationSourceUuid,
    billingMode,
    sourceContext,
    targetTab,
    sourceComponent,
    templateId,
  } = getCrossFlowParams();

  const onComplete = useCallback(() => stripCrossFlowParams(), []);

  const extensions = useMemo(() => {
    const startExtensions = createStartExtensions({
      edition,
      migrationSourceUuid,
    });
    const pageExtensions =
      sourceContext &&
      createProvisioningPageExtensions({
        actions: {
          primary: {
            behaviour: Behaviours.LAND,
            prompt: Prompts.GO_TO_PRODUCT,
            options: {
              targetTab: targetTab || TargetTabs.NEW,
              urlParams: {
                startCrossFlowSrcContext: sourceContext,
                ...(templateId && { templateId }),
              },
            },
          },
          secondary: {
            behaviour: Behaviours.CLOSE,
            prompt: Prompts.CLOSE,
          },
        },
      });
    return { ...startExtensions, ...pageExtensions };
  }, [edition, migrationSourceUuid, sourceContext, targetTab, templateId]);

  /**
   * Mount CrossFlowProvider when `startTrialProduct` param is present.
   *
   * Purposefully not checking for `startTrialCloudId` since it is optional and there are use cases where it is expected to be
   * empty and Cross Flow to be invoked, e.g. for Team Central expansion via Start.
   */
  return startTrialProduct ? (
    <AtlasCrossFlowProvider
      cloudId={startTrialCloudId}
      analyticsClient={crossflowAnalyticsClient}
      locale={locale}
      plugins={[getUsersPlugin, getContainersPlugin]}
    >
      <CrossFlowConsumer
        journey={Journeys.GET_STARTED}
        sourceComponent={sourceComponent || 'redirect'}
        sourceContext={sourceContext || 'start'}
        targetProduct={startTrialProduct}
        experimentalOptions={{ isJsmEnabled: true, ...(billingMode === 'legacy' && { billingMode }) }}
        extensions={extensions}
        onComplete={onComplete}
      />
    </AtlasCrossFlowProvider>
  ) : null;
};

export default CrossFlowWAC;
