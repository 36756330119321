import { PATH_PREFIX } from '@townsquare/config/routes';
import Loadable from '@townsquare/loadable';
import { createGlobalRoute, createSiteRoute } from '@townsquare/router/create-route';

import { createKudosResource } from './resources/createKudosResource';
import { goalEmbedResource } from './resources/goalEmbedResource';
import { goalsDirectoryEmbedResource } from './resources/goalsDirectoryEmbedResource';
import { labelEmbedResource } from './resources/labelResource';
import { projectEmbedResource } from './resources/projectEmbedResource';
import { projectsDirectoryEmbedResource } from './resources/projectsDirectoryEmbedResource';
import { EMBED_ROUTE_NAMES } from './util';

export const goalEmbedRoute = createSiteRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "GoalEmbed" */ './components/Goal/GoalEmbed'),
    loading: () => null,
  }),
  exact: true,
  embed: true,
  name: EMBED_ROUTE_NAMES.goalEmbed,
  path: `${PATH_PREFIX.EMBEDS}/goal/:goalKey/:tabName(about|update)`,
  resources: [goalEmbedResource],
  subproduct: 'townsquare-goals',
});

export const projectEmbedRoute = createSiteRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "ProjectEmbed" */ './components/Project/ProjectEmbed'),
    loading: () => null,
  }),
  exact: true,
  embed: true,
  name: EMBED_ROUTE_NAMES.projectEmbed,
  path: `${PATH_PREFIX.EMBEDS}/project/:projectKey/:tabName(about|update)`,
  resources: [projectEmbedResource],
  subproduct: 'townsquare-projects',
});

export const goalsDirectoryEmbedRoute = createGlobalRoute({
  component: Loadable({
    loader: () =>
      import(/* webpackChunkName: "GoalsDirectoryEmbed" */ './components/GoalsDirectory/GoalsDirectoryEmbed'),
    loading: () => null,
  }),
  exact: true,
  embed: true,
  name: EMBED_ROUTE_NAMES.goalsDirectory,
  path: `${PATH_PREFIX.EMBEDS}/goals`,
  resources: [goalsDirectoryEmbedResource],
  subproduct: 'townsquare-goals',
});

export const projectsDirectoryEmbedRoute = createGlobalRoute({
  component: Loadable({
    loader: () =>
      import(/* webpackChunkName: "ProjectsDirectoryEmbed" */ './components/ProjectsDirectory/ProjectsDirectoryEmbed'),
    loading: () => null,
  }),
  exact: true,
  embed: true,
  name: EMBED_ROUTE_NAMES.projectsDirectory,
  path: `${PATH_PREFIX.EMBEDS}/projects`,
  resources: [projectsDirectoryEmbedResource],
  subproduct: 'townsquare-projects',
});

export const labelEmbedRoute = createSiteRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "LabelEmbed" */ './components/Label/LabelEmbed'),
    loading: () => null,
  }),
  exact: true,
  embed: true,
  name: EMBED_ROUTE_NAMES.label,
  path: `${PATH_PREFIX.EMBEDS}/label/:uuid?`,
  resources: [labelEmbedResource],
  subproduct: 'townsquare-labels',
});

export const createKudosEmbedRouteOld = createGlobalRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "CreateKudosEmbed" */ './components/Kudos/CreateKudosEmbed'),
    loading: () => null,
  }),
  embed: true,
  name: EMBED_ROUTE_NAMES.createKudosOld,
  path: '/give-kudos',
  resources: [createKudosResource],
  subproduct: 'townsquare-kudos',
  query: ['type?', 'recipientId?', 'unsavedMessage?'],
});

export const createKudosEmbedRoute = createSiteRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "CreateKudosEmbed" */ './components/Kudos/CreateKudosEmbed'),
    loading: () => null,
  }),
  embed: true,
  name: EMBED_ROUTE_NAMES.createKudos,
  path: `/kudos/give`,
  resources: [createKudosResource],
  subproduct: 'townsquare-kudos',
  query: ['type?', 'recipientId?', 'unsavedMessage?'],
});
