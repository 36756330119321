import { generatePath, Query, RouteResource } from 'react-resource-router';

import { PATH_PREFIX } from '@townsquare/config/routes';

import {
  OPTIONAL_BASE_PATH_PATTERN,
  ORG_PREFIX,
  REQUIRED_BASE_PATH_PATTERN,
  identifierPaths,
  orgIdentifierKey,
  orgPrefixKey,
  type optionalBasePathPattern,
} from './constants';
import { ActionHomePageRoute, GlobalHomePageRoute, HomepageRoute, HomepageRouteType, SiteHomePageRoute } from './types';

/**
 * Create a global route by adding an optional orgIdentifier to the path
 */
export const createGlobalRoute = (route: HomepageRoute): GlobalHomePageRoute => {
  return {
    ...route,
    path: `${OPTIONAL_BASE_PATH_PATTERN}${route.path}`,
    pathPattern: OPTIONAL_BASE_PATH_PATTERN,
    routeType: 'global',
  };
};

/**
 * Create a site route by adding a required orgIdentifier and siteIdentifier to the path
 */
export const createSiteRoute = (route: HomepageRoute): SiteHomePageRoute => {
  return {
    ...route,
    classicPath: route.path,
    path: `${REQUIRED_BASE_PATH_PATTERN}${route.path}`,
    pathPattern: REQUIRED_BASE_PATH_PATTERN,
    routeType: 'site',
  };
};

export const createActionRoute = (route: HomepageRoute): ActionHomePageRoute => {
  return {
    ...route,
    path: `${PATH_PREFIX.ACTION}${route.path}`,
    routeType: 'action',
  };
};

export const createOrgScopedPath = (
  path: string | undefined,
  orgIdentifier: string,
  query?: Query,
  params?: Parameters<typeof generatePath>[1],
): string => {
  const url = generatePath(path, {
    ...params,
    [orgPrefixKey]: ORG_PREFIX,
    [orgIdentifierKey]: orgIdentifier,
  });

  // eslint-disable-next-line no-restricted-syntax
  return query ? url + '?' + new URLSearchParams(query).toString() : url;
};

export const expandGlobalRoute = (route: GlobalHomePageRoute) => {
  const basePath = route.path.replace(OPTIONAL_BASE_PATH_PATTERN, '');
  const { orgPrefix, orgIdentifier, sitePrefix, siteIdentifier } = identifierPaths;

  if (basePath === '/') {
    // This makes path-to-regexp/RRR operate the way it did previosuly wrt. optional slashes
    // for the specific cases of /o/<uuid> and /o/<uuid>/s/<site-id>
    // the casts are a temporary workaround
    return [
      {
        ...route,
        path: `${orgPrefix}${orgIdentifier}${sitePrefix}${siteIdentifier}` as optionalBasePathPattern,
      },
      { ...route, path: `${orgPrefix}${orgIdentifier}` as optionalBasePathPattern },
      { ...route, path: basePath as optionalBasePathPattern },
    ];
  }

  return [
    {
      ...route,
      path: `${orgPrefix}${orgIdentifier}${sitePrefix}${siteIdentifier}${basePath}`,
    },
    { ...route, path: `${orgPrefix}${orgIdentifier}${basePath}` },
    { ...route, path: basePath },
  ] as GlobalHomePageRoute[];
};

export const applyResourcesToRoute = (route: HomepageRouteType, resources: Array<RouteResource>) => {
  resources.forEach(resource => {
    if (route.resources) {
      if (!route.resources.includes(resource)) {
        route.resources.push(resource);
      }
    } else {
      route.resources = [resource];
    }
  });

  return route;
};
