import { Inline, xcss } from '@atlaskit/primitives';
import React from 'react';

const styles = xcss({
  gridArea: 'title',
  font: 'font.heading.large',
});

export const Title = ({ children }: React.PropsWithChildren<unknown>) => {
  return (
    <Inline xcss={styles} alignBlock="center" space="space.100">
      {children}
    </Inline>
  );
};
