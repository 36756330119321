import { xcss, media, Grid } from '@atlaskit/primitives';
import React from 'react';

const styles = xcss({
  position: 'relative',
  gridTemplateColumns: '1fr auto',
  [media.above.xxs]: {
    paddingBlockStart: 'space.300',
    paddingInline: 'space.200',
    gridTemplateAreas: `
      "breadcrumbs"
      "title"
      "actions"
      "tabs"
    `,
  },
  [media.above.sm]: {
    paddingBlockStart: 'space.300',
    paddingInline: 'space.400',
    gridTemplateAreas: `
      "breadcrumbs actions"
      "title actions"
      "tabs tabs"
    `,
  },
  ':after': {
    content: '""',
    position: 'absolute',
    height: '1px',
    backgroundColor: 'color.background.neutral.hovered',
    insetBlockEnd: 'space.0',
    // Ensures border won't overflow on top of sidenav
    width: 'calc(100% - 1px)',
    insetInlineStart: '1px',
  },
});

export const GriffinMainHeader = ({ children }: React.PropsWithChildren<unknown>) => {
  return <Grid xcss={styles}>{children}</Grid>;
};
