// Based on Jira Frontend https://stash.atlassian.com/projects/JIRACLOUD/repos/jira-frontend/browse/src/packages/servicedesk/change-calendar/src/ui/event-popup-content/change-request-details/fields/loading-skeleton/styled.js

import { token } from '@atlaskit/tokens';
import styled, { keyframes } from 'styled-components';

import { type Props } from './types';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
export const LoadingSkeletonContainer = styled.div<{ margin?: string }>(({ margin = '0px' }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
  margin: margin,
}));

const shimmer = keyframes({
  '0%': {
    backgroundPosition: '100% 0',
  },
  '100%': {
    backgroundPosition: '-100% 0',
  },
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LoadingSkeleton = styled.div<Props>(
  // eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
  ({
    marginLeftPx = 0,
    marginBottomPx = 0,
    marginRightPx = 0,
    marginTopPx = 0,
    width = '100%',
    borderRadius = '3px',
    backgroundColor = token('color.skeleton.subtle'),
    shimmerColor = token('color.skeleton'),
    height = '12px',
  }) => ({
    animationDuration: '1.2s',
    animationFillMode: 'forwards',
    animationIterationCount: 'infinite',
    animationName: shimmer,
    animationTimingFunction: 'linear',
    backgroundSize: '300% 100%',
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    backgroundColor: backgroundColor,
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    backgroundImage: `linear-gradient( to right, transparent 0%, transparent 40%, ${shimmerColor} 50%, transparent 60%, transparent 100%)`,
    backgroundRepeat: 'no-repeat',
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    borderRadius: borderRadius,
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    height: height,
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    width: width,
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    marginRight: `${marginRightPx}px`,
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    marginTop: `${marginTopPx}px`,
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    marginBottom: `${marginBottomPx}px`,
    // eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
    marginLeft: `${marginLeftPx}px`,
  }),
);
