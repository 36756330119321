import { type ClientOptions, type OptionsWithDefaults, PerimeterType } from '@atlaskit/feature-gate-js-client';

import { featureFlagApiKey, featureFlagEnv } from '@townsquare/feature-flags/client-key';

export const featureGatesOptions: OptionsWithDefaults<ClientOptions> = {
  targetApp: 'atlas_web', // https://hello.atlassian.net/wiki/spaces/MEASURE/pages/2955970231/How-to+Use+TargetApps+in+Statsig
  apiKey: featureFlagApiKey,
  environment: featureFlagEnv,
  perimeter: PerimeterType.COMMERCIAL,
};
