import { GOAL_DIRECTORY_PAGE, GOAL_DIRECTORY_PATH } from '@townsquare/config/routes';
import { directoryActiveViewResource, directoryFavoriteViewsResource } from '@townsquare/directory-header/resources';
import Loadable from '@townsquare/loadable';
import { createGlobalRoute } from '@townsquare/router/create-route';
import { titanEapCrossFlowQueryResource } from '@townsquare/titan-eap-cross-flow/resource';
import { tqlExplainerResource } from '@townsquare/tql/resource';

import { goalDirectoryResource, goalDirectoryTableResource } from './resources/index';

export const goalDirectoryRoute = createGlobalRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "goalDirectory-view" */ './components/GoalDirectory'),
    loading: () => null,
  }),
  exact: true,
  name: GOAL_DIRECTORY_PAGE,
  path: GOAL_DIRECTORY_PATH,
  capabilities: ['goals_app:access'],
  resources: [
    goalDirectoryResource,
    goalDirectoryTableResource,
    tqlExplainerResource,
    directoryActiveViewResource,
    directoryFavoriteViewsResource,
  ],
  subproduct: 'townsquare-goals',
  enableRestrictedFallback: true,
});

export const goalNoWorkspaceDirectoryRoute = createGlobalRoute({
  component: Loadable({
    loader: () => import(/* webpackChunkName: "GoalsNoWorkspaceDirectory" */ './components/EmptyOrNonProvisionedState'),
    loading: () => null,
  }),
  exact: true,
  name: GOAL_DIRECTORY_PAGE,
  path: GOAL_DIRECTORY_PATH,
  resources: [titanEapCrossFlowQueryResource],
  subproduct: 'townsquare-goals',
});
