import { getSearchConfig, type TSearchConfigAPIResponse } from '@atlassian/search-client';
import { createResource } from 'react-resource-router/resources';

export const searchConfigurationResource = createResource<TSearchConfigAPIResponse>({
  getData: async (_, __) => {
    return await getSearchConfig({})
      .then(data => {
        return data;
      })
      .catch(() => {
        return {
          resources: [],
          intercomHmac: '',
          siteMetadata: [],
        };
      });
  },
  getKey: () => 'search-configuration-resource-key',
  type: 'SEARCH_CONFIGURATION',
});
