import { Milestone } from '@townsquare/milestones';
import { createRelayResource } from '@townsquare/relay-utils';
import type { ResourceContext } from '@townsquare/router';
import { getBooleanConfig } from '@townsquare/stat-sig/config';
import { isNavRefreshEnabled } from '@townsquare/stat-sig/nav4';

import ProjectViewAsideQuery, {
  type ProjectViewAsideQuery as ProjectViewAsideQueryType,
} from '../components/__generated__/ProjectViewAsideQuery.graphql';

export const projectViewAsideResource = createRelayResource<ProjectViewAsideQueryType>({
  getQuery: (routerContext, resourceContext) => {
    const context = resourceContext as ResourceContext;
    const areMilestonesEnabled = getBooleanConfig('townsquare_enable_milestones_in_sidebar');

    if (areMilestonesEnabled) {
      Milestone.preload();
    }

    return {
      query: ProjectViewAsideQuery,
      variables: {
        key: routerContext.match.params.id ?? '',
        trackViewEvent: 'DIRECT',
        workspaceId: context.workspace?.globalId,
        onboardingKeyFilter: 'PROJECT_SPOTLIGHT',
        areMilestonesEnabled,
        cloudId: context.workspace?.cloudId,
        isNavRefreshEnabled: isNavRefreshEnabled(resourceContext as ResourceContext),
      },
    };
  },
});
